<template>
  <div :class="['container', { parallax: parallax }]" ref="parallax">
    <PageTop :name="tile.name" :title="tile.title" :wmsize="tile.wmsize" :top="tile.pageTop" />
    <router-view v-slot="{ Component }">
      <transition name="pos">
        <component :is="Component" :key="Date.now()" />
      </transition>
    </router-view>
    <!--div class="logo-footer">
      <SvgImage :svg="mvtnLogo" />
    </div-->
  </div>
</template>

<script>
// @ is an alias to /src
import Menubar from '@/components/Menubar.vue'
import PageTop from '@/components/PageTop.vue'
//import SvgImage from '@/components/SvgImage'
import { mapGetters, mapMutations } from 'vuex'
//import { service } from '@/store/service'

export default {
  name: 'Item',

  components: {
    PageTop,
    Menubar
    //SvgImage
  },

  data() {
    return {
      //mvtnLogo: service.svg.mvtn.logo
    }
  },

  computed: {
    ...mapGetters(['menu', 'parallax']),

    tile() { return this.tileByName()(this.$route.name); }
  },

  /*mounted() {
    console.log('item mouned', window.innerWidth, this.$refs.parallax.clientWidth);
    console.log(this.$route);
  },*/

  updated() {
    this.$nextTick(() => {
      //console.log('updated', window.innerWidth, this.$refs.parallax.offsetWidth, this.$refs.parallax.clientWidth);
      this.setScrollWidth(this.$refs.parallax.offsetWidth - this.$refs.parallax.clientWidth);
    });
  },

  /*beforeRouteEnter(to, from, next) {
    next(vm => {
      console.log('beforeRouteEnter', window.innerWidth, vm.$refs.parallax.offsetWidth, vm.$refs.parallax.clientWidth);
    });
  },

  beforeRouteUpdate(to, from, next) {
    console.log('beforeRouteUpdate', window.innerWidth, this.$refs.parallax.offsetWidth, this.$refs.parallax.clientWidth);
    next();
  },*/

  methods: {
    ...mapGetters(['tileByName']),
    ...mapMutations(['setScrollWidth'])
  }
}
</script>

<style scoped lang="less">
@import '../styles/vars.less';

.container {
  //overflow-y: auto;
  //overflow-x: hidden;
  position: relative;

  :deep(.title-container) {
    width: 100%;

    &.scrollable {
      .mvtn-transition-pos();
      margin-top: 75px;

      &.scrolled { margin-top: 0; }
    }

    .title {
      font-size: 6vw;
      font-weight: 500;
      padding: 15px;
      color: @colorHeader;
      cursor: pointer;
      .mvtn-transition-color();

      @media @phone { color: @colorHeaderBright; }
      @media @tablet { color: @colorHeaderBright; }

      &:hover { color: @colorHeaderHover; }
    }

    .btn {
      font-size: 1em;

      &.icon {
        font-size: 2em;
        margin-right: 20px;
      }

      &.specs {
        padding: 5px 10px;
        border: 1px solid @colorLink;
        border-radius: 2px;
        margin-right: 15px;
        .mvtn-transition-all();

        &:hover {
          color: @colorBgMain;
          background-color: @colorSemiWhite;
          cursor: pointer;
        }
      }
    }
  }
}

//.content::v-deep {
.content {
  position: relative;
	//height: 0;
	width: 100%;
	padding: 0;
  //scroll-snap-type: y mandatory;
	//margin-top: 12vw;

	//& + .content {
	//	margin-top: 0;
  //}

  &.fixed { position: fixed; }

}

.pos-enter-active, .pos-leave-active {
  .mvtn-transition-pos(0.35s);
  padding-top: 14rem;
  height: 0;

  //& + .logo-footer { opacity: 0; }

  & + .content {
    padding-top: 0;
  }
  //.mvtn-transition(margin 10.25s, left 0.25s, right 0.25s, top 0.25s, bottom 0.25s;);
}
.pos-enter-from, .pos-leave-to /* .page-leave-active below version 2.1.8 */ {
  margin-left: 100%;
}

/*.parallax {
  perspective: 100px;
  height: 100vh;
  //height: var(--fi-height);
  overflow-x: hidden;
  overflow-y: auto;

  :deep(.parallax-group) {
    transform-style: preserve-3d;

    .parallax-layer {
      position: absolute;

      &:not(.crop) {
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }

      &.layer-base { transform: translateZ(0); }
      &.layer-back { transform: translateZ(-100px) scale(2); }
    }
  }
}*/

</style>
