<template>
  <transition
    name="tran"
    @enter="enter"
    @after-enter="afterEnter"
    @leave="leave"
  >
    <slot/>
  </transition>
</template>

<script>

export default {
  name: 'VTransition',

  /*props: {
    speed: String
  },*/

  /*created() {
    console.log(this.speed);
  },*/

  methods: {
    enter(element) {
      const width = getComputedStyle(element).width;

      element.style.width = width;
      element.style.position = 'absolute';
      element.style.visibility = 'hidden';
      element.style.height = 'auto';

      const height = getComputedStyle(element).height;

      element.style.width = null;
      element.style.position = null;
      element.style.visibility = null;
      element.style.height = 0;

      // Force repaint to make sure the
      // animation is triggered correctly.
      getComputedStyle(element).height;

      // Trigger the animation.
      // We use `requestAnimationFrame` because we need
      // to make sure the browser has finished
      // painting after setting the `height`
      // to `0` in the line above.
      requestAnimationFrame(() => {
        element.style.height = height;
      });
    },

    afterEnter(element) {
      element.style.height = 'auto';
    },

    leave(element) {
      const height = getComputedStyle(element).height;
      
      element.style.height = height;

      // Force repaint to make sure the
      // animation is triggered correctly.
      getComputedStyle(element).height;

      requestAnimationFrame(() => {
        element.style.height = 0;
      });
    }
  },
};

</script>

<style scoped lang="less">
@import '../styles/vars.less';
//@import '../styles/form.less';

/** {
  will-change: height;
  transform: translateZ(0);
  backface-visibility: hidden;
  perspective: 1000px;
}*/

.tran-enter-active,
.tran-leave-active {
  //.mvtn-transition-all-bezier();
  .mvtn-transition-all();
  //transition: height 0.3s ease-in-out;
  //transition-duration: var(--speed);
  overflow: hidden;
}

.tran-enter-from, .tran-leave-to {
  height: 0;
}

</style>
