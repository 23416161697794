<template>
  <div :style="style">
    <!--Menubar v-on:menuToggle="handleMenuToggle" :fixed="true"></Menubar-->
    <!--Menubar :fixed="true"></Menubar-->
    <transition name="poswm">
      <div class="watermark-container" :key="name" ref="wmcontainer">
        <PageTopImage v-if="image" :image="image" :vertical="vertical" ref="image" />
        <div class="watermark flex">{{ name }}</div>
        <div class="header-container" :data-name="name">
          <div class="header" v-html="title"></div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
// @ is an alias to /src
import Menubar from '@/components/Menubar.vue'
import { mapGetters, mapMutations } from 'vuex'
import PageTopImage from './PageTopImage.vue'
import { service } from '@/store/service'

export default {
  name: 'PageTop',

  components: {
    Menubar,
    PageTopImage
  },

  props: {
    name: String,
    title: String,
    wmsize: Number,
    top: Object
  },

  data() {
    return {
      //tile: this.tileByName()('figuur')
      menuToggle: false,
      //colorWM: '#ffffff07',
      //colorHeader: '#ffffff18',
      style: {
        '--watermark-vwsize': this.wmsize + 'vw',
        '--watermark-vhsize': this.wmsize + 'vh',
        '--color-wm': service.pageTop.colorWM, //this.top && this.top.colorWM ? this.top.colorWM : this.colorWM,
        '--color-header': service.pageTop.colorHeader //this.top && this.top.colorHeader ? this.top.colorHeader : this.colorHeader
      }
    }
  },

  computed: {
    ...mapGetters(['menu']),

    image() { return this.top ? this.top.image : null; },
    vertical() { return this.top && this.top.hasOwnProperty('vertical') ? this.top.vertical : false; }, // default vartical: false
    pageTop() {
      return {
        full: this.top ? !!this.top.full : null,
        image: !!this.image,
        wmHeight: this.$refs.wmcontainer.clientHeight
      };
    }
  },

  watch: {
    name(val) {
      //console.log('watch name', val);
      //console.log('watch--', this.name, val, this.$refs.image ? this.$refs.image.clientHeight : '---');
      //console.log(this.top, this.$data, this.top && this.top.colorWM ? this.top.colorWM : service.pageTop.colorWM);
      this.initStyles();
    }
  },

  mounted() {
    this.initStyles();
  },

  methods: {
    ...mapMutations(['setPageTop']),

    initStyles() {
      this.style['--color-wm'] = this.top && this.top.colorWM ? this.top.colorWM : service.pageTop.colorWM;
      this.style['--color-header'] = this.top && this.top.colorHeader ? this.top.colorHeader : service.pageTop.colorHeader;
      this.setPageTop(this.pageTop);
    }
  }
}
</script>

<style scoped lang="less">
@import '../styles/vars.less';

.watermark-container {
  /*.image {
    position: fixed;
    width: 100%;
  }*/

  .watermark {
    color: var(--color-wm); //@colorWaterMark;
    //align-items: baseline;
    font-size: var(--watermark-vwsize); //30vw;
    font-weight: 700;
    line-height: 1;
    margin-left: -2vw;
    position: fixed;
    z-index: 0;
    .mvtn-transition-color(1s);

    @media @phone {
      font-size: var(--watermark-vhsize); //30vh;
      transform: rotate(-90deg);
      /* transform-origin: 0 0; */
      transform-origin: left;
      bottom: 0;
      margin-left: 10vh;
      margin-bottom: -18vh;
    }
  }

  //.header-container::v-deep {
  .header-container {
    position: relative;
    padding: 4rem 15px 15px 15px;
    line-height: 1;
    text-align: right;
    width: 100%;
    height: 14rem;
    box-sizing: border-box;
    z-index: 100;

    &.fixed { position: fixed; }

    :deep(.header) {
      color: var(--color-header); //@colorHeader;
      font-size: 6vw;
      font-weight: 500;
      
      .mark {
        font-style: normal;

        &.s1 { font-size: 3vw; }
        &.s2 { font-size: 4vw; }
        &.s3 { font-size: 6vw; }
        &.bold { font-weight: 700; }
      }
    }

    @media @phone {
    }

    &::before {
      color: @colorTileText;
      font-size: 7rem;
      font-weight: 200;
      margin-left: -4px;
      text-align: left;

      @media @phone {
        content: attr(data-name);
      }
    }
  }
}

.poswm-enter-active, .poswm-leave-active {
  .mvtn-transition-all(0.35s);

  .header-container {
    position: fixed;
  }
  //.mvtn-transition(margin 10.25s, left 0.25s, right 0.25s, top 0.25s, bottom 0.25s;);
}
.poswm-enter-from, .poswm-leave-to /* .page-leave-active below version 2.1.8 */ {
  margin-left: -200%;
}
//.poswm-enter-to { margin-left: 0; }

</style>
