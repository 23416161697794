<template>
  <div class="content" :style="style">
    <div :class="['text-container tuurro-bg', { 'fade': !loaded }]" ref="tuurro-bg">
      <div class="text-inner flex bottomv">
        <div class="flex wrap">
          <dl class="dlist" v-for="(item, index) in tile.description" :key="index">
            <dt v-html="item.title"></dt>
            <dd v-html="item.text"></dd>
          </dl>
        </div>
      </div>
      <!--img src="~@/assets/tuurro/tuurro.t6.f3tg.png" /-->
      <!--div class="text-to-right">text</div-->
    </div>

    <kinesis-container event="scroll" :duration="0" class="fixed-image-container">
    <!--div v-tscroll="handleScrollFic" class="fixed-image-container -scrollable" :style="ficStyle"-->
    <!--div class="fixed-image-container"-->
      <kinesis-element :strength="1000" type="translate">
        <!--div v-tscroll="handleScroll" class="fixed-image"-->
        <img class="fixed-image" :src="require('@/assets/tuurro/scene.2.jpg')" ref="scene" />
        <img v-scroll="{ threshold: imgThreshold, trigger: true }" class="fixed-image layer" :src="require('@/assets/tuurro/scene.2.layer2.png')" />
        <!--div :class="['layer', { show: colorButtons['black'] }]"></div-->
      </kinesis-element>
      <!--div class="half-screen-container flex centerv"-->
      <!--kinesis-element :strength="1000" class="half-screen-container flex centerv">
        <div class="half-screen flex column">
          <div
            v-for="(cb, i) in colorButtons"
            :class="['btn color', i, { selected: cb }]"
            :key="i"
            @click="selectColor(i)"
            :title="`Switch to ${i}`"
          ></div>
        </div>
      </kinesis-element-->
    </kinesis-container>

    <div class="bg-container" ref="fly" :key="flyingImagesHeight">
      <!--div class="image-container flex" ref="images">
        <div v-tscroll="handleScroll" class="image-item flex column scrollable bg-image-container" v-for="(img, i) in tile.images" :data-name="img.name" :key="i">
          <img class="img" :src="require(`@/assets/${img.src}`)" />
          <span class="img-title" v-html="img.name"></span>
        </div>
      </div-->
      <!--FlyingImages :images="tile.images" :thumb="true" :offset="flyingImagesHeight" /-->
      <FlyingImages :images="tile.images" :thumb="true" :key="vkey" />
      <div class="title-container backdrop-blur flex space" ref="specs">
        <div class="title" @click="toggleShowSpecs()">Specifications</div>
        <div class="btn-container flex centerv">
          <div :class="['btn specs', { shaded: !showSpecs }]" @click="toggleMetricImperial()">{{ btnMetricImperial }}</div>
          <div :class="['btn icon', { active: showSpecs }]" @click="toggleShowSpecs()" :title="showSpecsTitle">
            <font-awesome-icon icon="chevron-right" />
          </div>
        </div>
      </div>
      <VTransition>
        <div v-if="showSpecs" class="specs-container bot-bar flex column">
          <div class="bg-img-container"></div>
          <div class="specs-inner flex wrap gap">
            <SpecTable :specs="specsData()('tuurro', 'satellites')" />
            <SpecTable :specs="specsData()('tuurro', 'subwoofers')" />
          </div>
          <div class="note-container">
            <SpecNote :name="'tuurro'" />
          </div>
        </div>
      </VTransition>
      <div :class="['page-footer backdrop-blur red flex center', { active: showSpecs }]">
        <SvgImage :svg="mvtnLogo" />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import PageTop from '@/components/PageTop.vue';
import SpecTable from '@/components/SpecTable.vue';
import SpecNote from '@/components/SpecNote.vue';
import VTransition from '@/components/VTransition.vue';
import SvgImage from '@/components/SvgImage.vue';
import FlyingImages from '@/components/FlyingImages.vue';
import { mapGetters, mapMutations } from 'vuex';
import { service } from '@/store/service';

export default {
  name: 'Tuurro',

  components: {
    PageTop,
    SpecTable,
    SpecNote,
    VTransition,
    SvgImage,
    FlyingImages
  },

  data() {
    return {
      tile: this.tileByName()('tuurro'),
      showSpecs: false,
      loaded: false,
      ficMarginTop: 0,
      //ficActive: false,
      //fiScrolled: false,
      mvtnLogo: service.svg.mvtn.logo,
      /*colorButtons: {
        white: true,
        black: false
      },*/
      showBlack: false,
      vkeySalt: 0
    }
  },

  computed: {
    ...mapGetters(['menu', 'isMetric', 'isPhone', 'pageTop', 'pageTopImageLoaded', 'textContainerSize', 'flyingImagesHeight']),

    vkey() { return this.$route.name + this.vkeySalt; },
    btnMetricImperial() { return this.isMetric ? 'Imperial' : 'Metric'; },
    showSpecsTitle() { return this.showSpecs ? 'Hide specifications' : 'Show specifications'; },
    imgThreshold() { return service.createThresholds(20); },
    //textContainerHeight() { return (window.innerHeight < this.pageTop.imageHeight ? window.innerHeight : this.pageTop.imageHeight) - this.pageTop.wmHeight; },
    style() {
      return {
        '--tc-height': this.textContainerSize.height + 'px',
        //'--tc-width': this.textContainerSize.width + 'px',
        //'--tcwm-height': this.pageTop.wmHeight + 'px',
        //'--tci-height': this.pageTop.imageHeight + 'px'
        //'--fi-height': this.fiHeight + 'px'
      }
    },

    duration() { return '0'; }//return this.isPhone ? '1000' : '0'; }

    /*ficStyle() {
      return {
        'margin-top': this.ficMarginTop + 'px',
      }
    },*/
    //fiHeight() { return this.textContainerSize.width / this.tile.scenes[0].ratio; },
    /*fiStyle() {
      return {
        //'--src': `url(${require(`@/assets/tuurro/scene.2.jpg`)})`,
        //'--src-layer': `url(${require(`@/assets/tuurro/scene.2.layer2.png`)})`,
        '--fi-width': this.textContainerSize.width + 'px',
        //'--fi-height': this.fiHeight //this.textContainerSize.width / this.tile.scenes[0].ratio + 'px',
        //'height': this.imgHeight + 'px'
      }
    }*/
  },

  created() {
    //console.log('tuurro created', this.$refs);
    setTimeout(() => { this.loaded = true; }, 400);
    //this.setParallax(true);
  },

  mounted() {
    //console.log('tuurro mounted', this.$refs);
    this.vkeySalt = Date.now();

    //this.$nextTick(function() {
      //this.$refs.images.children.forEach(el => { service.handleScroll(el, 0); } );
    //});
  },

  /*watch: {
    pageTopImageLoaded(val, ov) {
      this.$nextTick(() => {
        if (val) this.$refs.images.children.forEach(el => { service.handleScroll(el, 0); });
      });
    }
  },*/

  methods: {
    ...mapGetters(['tileByName', 'specsData']),
    ...mapMutations(['toggleMetricImperial', 'setParallax']),

    toggleShowSpecs() {
      this.showSpecs = !this.showSpecs;

      setTimeout(() => {
        if (this.$refs.specs.offsetTop > window.innerHeight / 2) {
          window.scrollBy({ top: window.innerHeight / 2, left: 0, behavior: 'smooth' });
        }
      }, 100);
    },

    /*selectColor(color) {
      //console.log(color);
      for (let cb in this.colorButtons) this.colorButtons[cb] = false;
      this.colorButtons[color] = true;
    },*/

    /*handleScroll(e, el, node) {
      const top = el.getBoundingClientRect().top;
      const tuurroBgBot = this.$refs['tuurro-bg'] ? this.$refs['tuurro-bg'].getBoundingClientRect().bottom : top;
      
      //console.log('+++++', top, this.$refs['tuurro-bg'].getBoundingClientRect().bottom);
      //console.log('+++++', top, e, el, node);
      this.showBlack = top > tuurroBgBot ? true : false;
    }*/
  },

  /*unmounted() {
    //this.setParallax(false);
    //console.log('Tuurro destroyed');
  }*/
}
</script>

<style scoped lang="less">
@import '../styles/vars.less';

/*.tuurro-bg {
  //background-image: url(~@/assets/tuurro/tuurro.t6.f7.png);
  background-image: url(~@/assets/tuurro/tuurro.t6.f8.png);
  background-repeat: no-repeat;
  background-size: contain;
  //background-origin: border-box;
  background-position: 50% 0;
  background-attachment: fixed;
  min-height: 88vh;
  .mvtn-transition(filter 0.5s);

  &.fade { filter: invert(1) opacity(0.3); }
}*/

.tuurro-bg {
  position: relative;
  z-index: 5;
  min-height: var(--tc-height);

  .text-inner {
    width: 50%;
    margin-left: 50%;
    font-size: 1.4em;
    padding-bottom: 30px;

    @media @phone {
      width: 100%;
      margin-left: 0;
    }

    .dlist {
      flex-basis: 90%;
    }
  }
}

.fixed-image-container {
  position: relative;
  overflow: hidden;
  //height: var(--fi-height);
  //background-color: #222;
  //scroll-snap-align: start;

  .fixed-image {
    //width: 100%;
    //height: 100%;
    //height: var(--tc-height);
    //.mvtn-transition(transform 5s);

    @media @phone { transform: translateX(-33.5%) scale(0.53); }

    &.layer {
      position: absolute;
      left: 0;
      top: 0;
      //width: 100%;
      //height: 100%;
      //height: var(--tc-height);
      opacity: 0;
      .mvtn-transition-2(transform 5s, opacity 1s);

      &.scroll-enter { opacity: 1; }
    }
  }

  /*.half-screen-container {
    position: absolute;
    top: calc(50% - 9rem);

    .half-screen {
      background-color: #554444cc; //#00000055;
      padding: 1rem;
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
    }
  }*/

  //&.scroll-enter {
  //  .layer { opacity: 1; }
  //}
}

.bg-container {
  position: relative;
  z-index: 10;

  .images-container::v-deep {
    .image-item {	background-image: linear-gradient(to bottom, #baa, #333); }
  }

  .specs-container {
    .specs-container();
    background-color: @colorBgMain;
    //backdrop-filter: blur(10px);

    .bg-img-container {
      background-image: url(~@/assets/tuurro/26W-4558T00x.png);
    }

    &.bot-bar {
      background-image: url(~@/assets/tuurro/tuurro.t6.bar.png);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: bottom;

      @media @phone {
        background-size: auto;
        background-position: 16% 100%;
      }
    }
  }
}

/*.blocked {
  overflow: hidden;
  height: 100%;

  .tuurro-bg {
    height: 0;
    overflow: hidden;
  }
}*/

/*.container::v-deep {
  .watermark-container {
    .image {
      width: 50%;
    }
  }
}*/


</style>
