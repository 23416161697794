// FIGUUR
const tile = {
  name: 'FIGUUR',
  url: '/figuur',
  title: 'Looks not like a speaker',
  wmsize: 26.5, //30,
  img: 'figuur/figuur01.png',
  images: [
    [ { src: 'figuur/figuura02.png', name: 'Figuur.A' } ],
    [ { src: 'figuur/figuurc02.png', name: 'Figuur.C' } ],
    [ { src: 'figuur/figuurb02.png', name: 'Figuur.B' } ]
  ],
  description: [
    {
      title: "Technology",
      text: "Figuur does not have visible speakers and has an extremely wide dispersion of nearly 180"
    },
    {
      title: "Design",
      text: "It looks like decor element with veneer, paint or metal finish.",
    },
    {
      title: "How does it work?",
      text: "In general, to produce sound a speaker needs to move the air. Figuur speakers differ from traditional speakers in the way they produce sound. The flat surface of the Figuur bends to produce concentric waves like the ripples of water when a stone is dropped in a pond, instead of forcing the diaphragm membrane to move up and down like a piston."
    }
  ],
  over: false,
  specs: {
    satellites: {
      type: 'Satellites',
      head: {
        power: 'Power handling (RMS)',
        impedance: 'Impedance',
        sensetivity: 'Sensitivity (1W/1m)',
        fresponce: 'Frequency response (±3dB)', // &#42;
        protection: 'Protection',
        finish: 'Finish &#xb3;',
        dimension: 'Dimensions (W x H x D)',
        weight: 'Weight'
      },
      items: [
        {
          name: 'Figuur A',
          image: 'figuur/figuurA', //'figuur/figuur.a.svg',
          power: '',        //'80W',
          impedance: '',    //'8&Omega;',
          sensetivity: '',  //'90dB',
          fresponce: '',    //'125Hz -- 20kHz',
          protection: '',   //'Self-resetting',
          finish: '',
          dimension: {
            metric: '485 x 505 x 56mm',
            imperial: '19.1" x 19.9" x 2.2"'
          },
          weight: {
            metric: '?? kg',
            imperial: '?? lbs'
          }
        },
        {
          name: 'Figuur C',
          image: 'figuur/figuurC', //'figuur/figuur.c.svg',//{
          power: '80W',
          impedance: '8&Omega;',
          sensetivity: '90dB',
          fresponce: '125Hz &#x2027;&#x2027; 20kHz',
          protection: 'Self-resetting',
          finish: 'Veneer',
          dimension: {
            metric: '515 x 436 x 56mm',
            imperial: '19.1" x 19.9" x 2.2"'
          },
          weight: {
            metric: '?? kg',
            imperial: '?? lbs'
          }
        },
        {
          name: 'Figuur B',
          image: 'figuur/figuurB', //'figuur/figuur.b.svg',//{
          power: '',        //'80W',
          impedance: '',    //'8&Omega;',
          sensetivity: '',  //'90dB',
          fresponce: '',    //'125Hz -- 20kHz',
          protection: '',   //'Self-resetting',
          finish: '',
          dimension: {
            metric: '485 x 505 x 56mm',
            imperial: '19.1" x 19.9" x 2.2"'
          },
          weight: {
            metric: '?? kg',
            imperial: '?? lbs'
          }
        }
      ]
    },

    subwoofers: {
      type: 'Subwoofers',
      head: {
        amplifier: {
          name: 'Amplifier',
          power: 'Power (RMS)',
          damping: 'Damping factor',
          dynamicRange: 'Dynamic range',
          thd: 'THD+N @ 1W / 260W',
          protection: 'Protection'
        },
        inputs: {
          name: 'Inputs &#xb9;',  // superscript 1
          unbalanced: 'Unbalanced',
          balanced: 'Balanced'
        },
        outputs: {
          name: 'Outputs &#xb9;', // superscript 1
          unbalanced: 'Unbalanced',
          balanced: 'Balanced'
        },
        dsp: {
          name: 'DSP &#xb2;', // superscript 2
          filter: 'Filters',
          eq: 'Parametric EQs',
          delay: 'Delay',
          features: 'Matrix mixer / Invert polarity'
        },
        driver: {
          name: 'Driver',
          cone: 'Cone',
          xmax: 'Linear excursion',
          feature: 'Feature'
        },
        powerp: 'Power handling (RMS)',
        impedance: 'Impedance',
        sensetivity: 'Sensitivity (2.83V/1m)',
        fresponce: 'Frequency response (±3dB)', // &#42;
        //protection: 'Protection',
        finish: 'Finish &#xb3;',
        dimension: 'Dimensions (W x H x D)',
        weight: 'Weight'
      },
      items: [
        {
          name: 'Figuur S.10',
          //image: 'figuur/figuur.a.svg',
          amplifier: '&#x2012',
          inputs: '&#x2012',
          outputs: '&#x2012',
          dsp: '&#x2012',
          driver: {
            name: '1 x 10"',
            cone: 'Dual rigid aluminum',
            xmax: '24mm',
            feature: 'Shallow'
          },
          powerp: '250W',
          impedance: '8&Omega;',
          sensetivity: '83dB',
          fresponce: '35 &#x2027;&#x2027; 300Hz',
          //protection: '',   //'Self-resetting',
          finish: 'Veneer',
          dimension: {
            metric: '? x ? x ?mm',
            imperial: '?.?" x ?.?" x ?.?"'
          },
          weight: {
            metric: '?? kg',
            imperial: '?? lbs'
          }
        },
        {
          name: 'Figuur S.10P',
          //image: 'figuur/figuur.c.svg',//{
          amplifier: {
            name: 'ICEpower',
            power: '300W',
            damping: '660',
            dynamicRange: '113dBA',
            thd: '0.005% / 0.1%',
            protection: '<i class="r-icon" title="Over temperature">OT</i><i class="r-icon" title="Over current">OC</i><i class="r-icon" title="Short circuit">SC</i>'
          },
          inputs: {
            name: 'Configurable via DSP',
            unbalanced: '2 x RCA',
            balanced: '2 x XLR/TRS combo'
          },
          outputs: {
            name: 'Configurable via DSP',
            unbalanced: '2 x RCA',
            balanced: '2 x XLR'
          },
          dsp: {
            name: '56bit / 48kHz', // superscript 2
            filter: 'LPF / HPF',
            eq: 'Peak / Shelf',
            delay: 'Up to 7.5ms (258cm)',
            features: '+ / +'
          },
          driver: {
            name: '1 x 10"',
            cone: 'Dual rigid aluminum',
            xmax: '24mm',
            feature: 'Shallow'
          },
          powerp: '&#x2012',
          impedance: '&#x2012',
          sensetivity: '&#x2012',
          //fresponce: '25 &#x2027;&#x2027; 200Hz',
          fresponce: {
            name: '25 &#x2027;&#x2027; 300Hz',  // &#x2074; superscript 4
            title: 'With DSP'
          },
            //protection: 'Self-resetting',
          finish: 'Veneer',
          dimension: {
            metric: '? x ? x ?mm',
            imperial: '?.?" x ?.?" x ?.?"'
          },
          weight: {
            metric: '?? kg',
            imperial: '?? lbs'
          }
        }
      ]
    },

    amplifiersd: {
      type: 'Amplifiers with DSP',
      head: {
        amplifier: {
          name: 'Amplifier',
          channels: 'Channels',
          power4: 'Power @ 4&Omega; (RMS)',
          power8: 'Power @ 8&Omega; (RMS)',
          damping: 'Damping factor',
          dynamicRange: 'Dynamic range',
          thd: 'THD+N @ 1W',
          protection: 'Protection'
        },
        inputs: {
          name: 'Inputs &#xb9;',  // superscript 1
          unbalanced: 'Unbalanced',
          balanced: 'Balanced'
        },
        outputs: {
          name: 'Outputs &#xb9;', // superscript 1
          unbalanced: 'Unbalanced',
          balanced: 'Balanced'
        },
        dsp: {
          name: 'DSP &#xb2;', // superscript 2
          filter: 'Filters',
          eq: 'Parametric EQs',
          delay: 'Delay',
          features: 'Matrix mixer / Invert polarity'
        },
        fresponce: 'Frequency response (±0.15dB)', // &#42;
        //protection: 'Protection',
        finish: 'Finish &#xb3;',
        dimension: 'Dimensions (W x H x D)',
        weight: 'Weight'
      },
      items: [
        {
          name: 'Figuur D.1303',
          //image: 'figuur/figuur.a.svg',
          amplifier: {
            name: 'ICEpower',
            channels: '3',
            power4: '1 x 720W + 2 x 300W (1% THD+N)',
            power8: '1 x 360W + 2 x 150W (1% THD+N)',
            damping: '660 @ 4&Omega; / 1320 @ 8&Omega;',
            dynamicRange: '117dBA (1ch) / 113dBA (2ch)',
            thd: '0.005% @ 4&Omega; / 0.003% @ 8&Omega;',
            protection: '<i class="r-icon" title="Over temperature">OT</i><i class="r-icon" title="Over current">OC</i><i class="r-icon" title="Short circuit">SC</i>'
          },
          inputs: {
            name: 'Configurable via DSP',
            unbalanced: '2 x RCA',
            balanced: '2 x XLR/TRS combo'
          },
          outputs: {
            name: 'Configurable via DSP',
            unbalanced: '1 x RCA',
            balanced: '1 x XLR'
          },
          dsp: {
            name: '56bit / 48kHz', // superscript 2
            filter: 'LPF / HPF',
            eq: 'Peak / Shelf',
            delay: 'Up to 7.5ms (258cm)',
            features: '+ / +'
          },
          fresponce: '20Hz &#x2027;&#x2027; 20kHz',
          //protection: '',   //'Self-resetting',
          finish: 'Veneer',
          dimension: {
            metric: '? x ? x ?mm',
            imperial: '?.?" x ?.?" x ?.?"'
          },
          weight: {
            metric: '?? kg',
            imperial: '?? lbs'
          }
        },
        {
          name: 'Figuur D.701',
          //image: 'figuur/figuur.a.svg',
          amplifier: {
            name: 'ICEpower',
            channels: '1',
            power4: '1 x 720W (1% THD+N)',
            power8: '1 x 360W (1% THD+N)',
            damping: '660 @ 4&Omega; / 1320 @ 8&Omega;',
            dynamicRange: '117dBA',
            thd: '0.005% @ 4&Omega; / 0.003% @ 8&Omega;',
            protection: '<i class="r-icon" title="Over temperature">OT</i><i class="r-icon" title="Over current">OC</i><i class="r-icon" title="Short circuit">SC</i>'
          },
          inputs: {
            name: 'Configurable via DSP',
            unbalanced: '2 x RCA',
            balanced: '2 x XLR/TRS combo'
          },
          outputs: {
            name: 'Configurable via DSP',
            unbalanced: '3 x RCA',
            balanced: '3 x XLR'
          },
          dsp: {
            name: '56bit / 48kHz', // superscript 2
            filter: 'LPF / HPF',
            eq: 'Peak / Shelf',
            delay: 'Up to 7.5ms (258cm)',
            features: '+ / +'
          },
          fresponce: '20Hz &#x2027;&#x2027; 20kHz',
          //protection: '',   //'Self-resetting',
          finish: 'Veneer',
          dimension: {
            metric: '? x ? x ?mm',
            imperial: '?.?" x ?.?" x ?.?"'
          },
          weight: {
            metric: '?? kg',
            imperial: '?? lbs'
          }
        },
        {
          name: 'Figuur D.1004',
          //image: 'figuur/figuur.a.svg',
          amplifier: {
            name: 'ICEpower',
            channels: 'Configurable 4, 3 or 2',
            power4: '2 x 450W / 4 x 130W (1% THD+N)',
            power8: '2 x 250W / 4 x 70W (1% THD+N)',
            damping: '250 @ 4&Omega; / 500 @ 8&Omega;',
            dynamicRange: '121dBA (2ch) / 117dBA (4ch)',
            thd: '0.008% @ 4&Omega; / 0.004% @ 8&Omega;',
            protection: '<i class="r-icon" title="Over temperature">OT</i><i class="r-icon" title="Over current">OC</i><i class="r-icon" title="Short circuit">SC</i>'
          },
          inputs: {
            name: 'Configurable via DSP',
            unbalanced: '2 x RCA',
            balanced: '2 x XLR/TRS combo'
          },
          outputs: '&#x2012',
          dsp: {
            name: '56bit / 48kHz', // superscript 2
            filter: 'LPF / HPF',
            eq: 'Peak / Shelf',
            delay: 'Up to 7.5ms (258cm)',
            features: '+ / +'
          },
          fresponce: '20Hz &#x2027;&#x2027; 20kHz',
          //protection: '',   //'Self-resetting',
          finish: 'Veneer',
          dimension: {
            metric: '? x ? x ?mm',
            imperial: '?.?" x ?.?" x ?.?"'
          },
          weight: {
            metric: '?? kg',
            imperial: '?? lbs'
          }
        },
        {
          name: 'Figuur D.502',
          //image: 'figuur/figuur.a.svg',
          amplifier: {
            name: 'ICEpower',
            channels: 'Configurable 2 or 1',
            power4: '1 x 450W / 2 x 130W (1% THD+N)',
            power8: '1 x 250W / 2 x 70W (1% THD+N)',
            damping: '250 @ 4&Omega; / 500 @ 8&Omega;',
            dynamicRange: '121dBA (1ch) / 117dBA (2ch)',
            thd: '0.008% @ 4&Omega; / 0.004% @ 8&Omega;',
            protection: '<i class="r-icon" title="Over temperature">OT</i><i class="r-icon" title="Over current">OC</i><i class="r-icon" title="Short circuit">SC</i>'
          },
          inputs: {
            name: 'Configurable via DSP',
            unbalanced: '2 x RCA',
            balanced: '2 x XLR/TRS combo'
          },
          outputs: {
            name: 'Configurable via DSP',
            unbalanced: '2 x RCA',
            balanced: '2 x XLR'
          },
          dsp: {
            name: '56bit / 48kHz', // superscript 2
            filter: 'LPF / HPF',
            eq: 'Peak / Shelf',
            delay: 'Up to 7.5ms (258cm)',
            features: '+ / +'
          },
          fresponce: '20Hz &#x2027;&#x2027; 20kHz',
          //protection: '',   //'Self-resetting',
          finish: 'Veneer',
          dimension: {
            metric: '? x ? x ?mm',
            imperial: '?.?" x ?.?" x ?.?"'
          },
          weight: {
            metric: '?? kg',
            imperial: '?? lbs'
          }
        },
        {
          name: 'Figuur D.102',
          //image: 'figuur/figuur.a.svg',
          amplifier: {
            name: 'ICEpower',
            channels: '2',
            power4: '2 x 47W (1% THD+N)',
            power8: '2 x 22W (1% THD+N)',
            damping: '250 @ 4&Omega; / 500 @ 8&Omega;',
            dynamicRange: '120dBA',
            thd: '0.006% @ 4&Omega; / 0.003% @ 8&Omega;',
            protection: '<i class="r-icon" title="Over temperature">OT</i><i class="r-icon" title="Over current">OC</i><i class="r-icon" title="Short circuit">SC</i>'
          },
          inputs: {
            name: 'Configurable via DSP',
            unbalanced: '2 x RCA',
            balanced: '2 x XLR/TRS combo'
          },
          outputs: {
            name: 'Configurable via DSP',
            unbalanced: '2 x RCA',
            balanced: '2 x XLR'
          },
          dsp: {
            name: '56bit / 48kHz', // superscript 2
            filter: 'LPF / HPF',
            eq: 'Peak / Shelf',
            delay: 'Up to 7.5ms (258cm)',
            features: '+ / +'
          },
          fresponce: '20Hz &#x2027;&#x2027; 20kHz',
          //protection: '',   //'Self-resetting',
          finish: 'Veneer',
          dimension: {
            metric: '? x ? x ?mm',
            imperial: '?.?" x ?.?" x ?.?"'
          },
          weight: {
            metric: '?? kg',
            imperial: '?? lbs'
          }
        }
      ]
    },

    amplifiersp: {
      type: 'Amplifiers',
      head: {
        amplifier: {
          name: 'Amplifier',
          channels: 'Channels',
          power4: 'Power @ 4&Omega; (RMS)',
          power8: 'Power @ 8&Omega; (RMS)',
          damping: 'Damping factor',
          dynamicRange: 'Dynamic range',
          thd: 'THD+N @ 1W',
          protection: 'Protection'
        },
        inputs: {
          name: 'Inputs &#xb9;',  // superscript 1
          unbalanced: 'Unbalanced',
          balanced: 'Balanced'
        },
        fresponce: 'Frequency response (±0.15dB)', // &#42;
        //protection: 'Protection',
        finish: 'Finish &#xb3;',
        dimension: 'Dimensions (W x H x D)',
        weight: 'Weight'
      },
      items: [
        {
          name: 'Figuur P.701',
          //image: 'figuur/figuur.a.svg',
          amplifier: {
            name: 'ICEpower',
            channels: '1',
            power4: '1 x 720W (1% THD+N)',
            power8: '1 x 360W (1% THD+N)',
            damping: '660 @ 4&Omega; / 1320 @ 8&Omega;',
            dynamicRange: '117dBA',
            thd: '0.005% @ 4&Omega; / 0.003% @ 8&Omega;',
            protection: '<i class="r-icon" title="Over temperature">OT</i><i class="r-icon" title="Over current">OC</i><i class="r-icon" title="Short circuit">SC</i>'
          },
          inputs: {
            name: 'Switchable',
            unbalanced: '1 x RCA',
            balanced: '1 x XLR/TRS combo'
          },
          fresponce: '20Hz &#x2027;&#x2027; 20kHz',
          //protection: '',   //'Self-resetting',
          finish: 'Veneer',
          dimension: {
            metric: '? x ? x ?mm',
            imperial: '?.?" x ?.?" x ?.?"'
          },
          weight: {
            metric: '?? kg',
            imperial: '?? lbs'
          }
        },
        {
          name: 'Figuur P.502',
          //image: 'figuur/figuur.a.svg',
          amplifier: {
            name: 'ICEpower',
            channels: 'Configurable 2 or 1',
            power4: '1 x 450W / 2 x 130W (1% THD+N)',
            power8: '1 x 250W / 2 x 70W (1% THD+N)',
            damping: '250 @ 4&Omega; / 500 @ 8&Omega;',
            dynamicRange: '121dBA (1ch) / 117dBA (2ch)',
            thd: '0.008% @ 4&Omega; / 0.004% @ 8&Omega;',
            protection: '<i class="r-icon" title="Over temperature">OT</i><i class="r-icon" title="Over current">OC</i><i class="r-icon" title="Short circuit">SC</i>'
          },
          inputs: {
            name: 'Switchable',
            unbalanced: '2 x RCA',
            balanced: '2 x XLR/TRS combo'
          },
          fresponce: '20Hz &#x2027;&#x2027; 20kHz',
          //protection: '',   //'Self-resetting',
          finish: 'Veneer',
          dimension: {
            metric: '? x ? x ?mm',
            imperial: '?.?" x ?.?" x ?.?"'
          },
          weight: {
            metric: '?? kg',
            imperial: '?? lbs'
          }
        },
        {
          name: 'Figuur P.102',
          //image: 'figuur/figuur.a.svg',
          amplifier: {
            name: 'ICEpower',
            channels: '2',
            power4: '2 x 47W (1% THD+N)',
            power8: '2 x 22W (1% THD+N)',
            damping: '250 @ 4&Omega; / 500 @ 8&Omega;',
            dynamicRange: '120dBA',
            thd: '0.006% @ 4&Omega; / 0.003% @ 8&Omega;',
            protection: '<i class="r-icon" title="Over temperature">OT</i><i class="r-icon" title="Over current">OC</i><i class="r-icon" title="Short circuit">SC</i>'
          },
          inputs: {
            name: 'Switchable',
            unbalanced: '2 x RCA',
            balanced: '2 x XLR/TRS combo'
          },
          fresponce: '20Hz &#x2027;&#x2027; 20kHz (&#xb1;0.15dB)',
          //protection: '',   //'Self-resetting',
          finish: 'Veneer',
          dimension: {
            metric: '? x ? x ?mm',
            imperial: '?.?" x ?.?" x ?.?"'
          },
          weight: {
            metric: '?? kg',
            imperial: '?? lbs'
          }
        }
      ]
    },

    note: {
      name: 'Notes',
      items: [
        {
          id: 1,
          name: 'Inputs/Outputs options', //'&#xb9;',
          texts: [
            {
              //name: 'Filters',
              text: `<div class="flex column">
                      All amplifiers available in Balanced and Unbalanced versions.</span>
                      <ul><li>Balanced: Figuur X.NNNN (D.701)</li>
                      <li>Unalanced: Figuur X.NNNN.u (D.701.u)</li></ul>
                    </div>`
            }
          ],
          foot: {
            name: 'Note:',
            text: `<span>Balanced and Unbalanced Inputs/Outputs do not work simultaneously.</span>`
          }
        },
        {
          id: 2,
          name: 'DSP', //'&#xb2;',
          texts: [
            {
              name: 'Filters',
              text: `<ul>
                      <li>Butterworth up to 8th order (6 &#x2027;&#x2027; 48dB/oct)</li>
                      <li>Linkwitz-Riley up to 8th order (12 &#x2027;&#x2027; 48dB/oct)</li>
                      <li>Bessel 2nd order</li>
                      <li>Advanced mode for custom biquad input</li>
                    </ul>`
            },
            {
              name: 'Parametric EQs',
              text: `<ul>
                      <li>5 bands per input</li>
                      <li>5 bands per output</li>
                      <li>Peak / Shelf switchable</li>
                    </ul>`
            }
          ]
        },
        {
          id: 3,
          name: 'Finish options', //'&#xb9;',
          texts: [
            {
              //name: 'Filters',
              text: `<ul>
                      <li>Veneer (nearly any)</li>
                      <li>Matt lacquer (any color)</li>
                      <li>High-gloss lacquer (any color)</li>
                      <li>Photo-printed picture under lacquer</li>
                      <li>Custom finish upon request</li>
                    </ul>`
            }
          ],
        }
      ]
    }
  }
}

export const figuur = { tile }
