<template>
  <div class="container">
    <div class="tile-container">
      <router-link :to="tileByName('tuurro').url" class="tile-link">
        <Tile :tile="tileByName('tuurro')"></Tile>
      </router-link>
      <router-link :to="tileByName('figuur').url" class="tile-link">
        <Tile :tile="tileByName('figuur')"></Tile>
      </router-link>
      <router-link :to="tileByName('framee').url" class="tile-link">
        <Tile :tile="tileByName('framee')"></Tile>
      </router-link>
    </div>
    <Menubar />
    <div class="tile-container">
      <router-link :to="tileByName('plaano').url" class="tile-link">
        <Tile :tile="tileByName('plaano')"></Tile>
      </router-link>
      <router-link :to="tileByName('kaddro').url" class="tile-link">
        <Tile :tile="tileByName('kaddro')"></Tile>
      </router-link>
      <router-link :to="tileByName('sferro').url" class="tile-link">
        <Tile :tile="tileByName('sferro')"></Tile>
      </router-link>
    </div>

    <!--Test /-->
    <!--HelloWorld /-->
  </div>
</template>


<!--script lang="ts">
import { defineComponent } from 'vue';
import HelloWorld from '@/components/HelloWorld.vue'; // @ is an alias to /src

export default defineComponent({
  name: 'Home',
  components: {
    HelloWorld,
  },
});
</script-->

<script>
// @ is an alias to /src
import { defineComponent } from 'vue';
import HelloWorld from '@/components/HelloWorld.vue'; // @ is an alias to /src
import Tile from '@/components/Tile.vue'
import Menubar from '@/components/Menubar.vue'
import { mapGetters } from 'vuex'
//import Test from '@/components/Test.vue'

export default defineComponent({
  name: 'Home',

  components: {
    HelloWorld,
    Tile,
    Menubar,
    //Test
  },

  data() {
    return {
      //menuItems: ['PLAANO', 'TUURRO']
    }
  },

  computed: {
    ...mapGetters(['menu', 'tileByName'])
  }
});
</script>

<style scoped lang="less">
//@import '../styles/vars.less';

.container {
  position: fixed;
}

.tile-container {
  display: flex;
  height: calc(50% - 20px);
  box-sizing: border-box;

  .tile-link {
    text-decoration: none;
    outline: none;
    flex: 1;
  }
}

</style>
