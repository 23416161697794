import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faInstagram, faFacebookSquare } from '@fortawesome/free-brands-svg-icons'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import VueKinesis from 'vue-kinesis'
import MvtnScroll from './directives/MvtnScroll'

library.add(faInstagram, faFacebookSquare, faChevronRight)

const app = createApp(App);
app.use(store).use(router).use(VueKinesis).component('font-awesome-icon', FontAwesomeIcon);

app.directive('tscroll', {
  beforeMount(el, binding, node) {
    //console.log('---', el, binding, node);
    return;
    let f = function (evt: any) {
      //console.log(evt);
      if (binding.value(evt, el, node)) { window.removeEventListener('scroll', f); }
    }
    window.addEventListener('scroll', f);
  },
  /*unbind: function(el, binding) {
    console.log('unbind:', el, binding);
  }*/
});

/*app.directive('pscroll', {
  beforeMount(el, binding, node) {
    //console.log('---pscroll', el, binding, node);
    const prlx = document.querySelector('.parallax');
    let f = function (evt: any) {
      //console.log(evt);
      if (binding.value(evt, el, node)) {
        if (prlx) prlx.removeEventListener('scroll', f);
      }
    }
    if (prlx) prlx.addEventListener('scroll', f);
  },
  //unbind: function(el, binding) { console.log('unbind:', el, binding); }
});*/

/*const scrollObserver = new IntersectionObserver((entries, scrollObserver) => {
  entries.forEach((entry) => {
    console.log(entry);
    if (entry.isIntersecting) {
      entry.target.classList.add('scroll-enter');
      //scrollObserver.unobserve(entry.target);
    }
    else {
      entry.target.classList.remove('scroll-enter');
    }
  })
}, { threshold: 0.9 });

app.directive('scroll', {
  beforeMount(el, binding, vnode, pnode) {
    el.classList.add('scroll-before-enter');
    scrollObserver.observe(el);
  }
});*/

app.directive('scroll', MvtnScroll);

app.mount('#app');
