<template>
  <!--div :class="['menubar flex space', { fixed: fixed && notfixed }, {active: menuToggle}]" :style="menuStyle" ref="menuBar"-->
  <div :class="['menubar flex space', { fixed: fixed }, {active: menuToggle}]" :style="style" ref="menuBar">
		<router-link to="/" class="logobox flex centerv">
			<div class="logoitem logo-m"></div>
			<div class="logoitem logo-o"></div>
			<div class="logoitem logo-v"></div>
			<div class="logoitem logo-i"></div>
			<div class="logoitem logo-t"></div>
			<div class="logoitem logo-o"></div>
			<div class="logoitem logo-n"></div>
			<div class="logoitem logo-e"></div>
		</router-link>

		<div class="menu flex" ref="menu">
			<router-link class="link" v-for="(item, index) in menu"
				:key="index"
				:to="item.url"
				:class="[{ active: item.over }, { animate: animate[index] }]"
				@mouseover="mOver(item, index)"
				@mouseout="mOut(item, index)"
			>
				{{ item.name }}
			</router-link>
      <div v-if="menuButtonShow" :class="['page-footer red flex center shaded', { unshaded: unshadeLogo }]">
        <SvgImage :svg="mvtnLogo" />
      </div>
		</div>

		<div class="icons flex">
			<a class="link"><font-awesome-icon :icon="['fab', 'instagram']" /></a>
			<a class="link"><font-awesome-icon :icon="['fab', 'facebook-square']" /></a>
			<a v-if="menuButtonShow" class="link menu-button" @click="toggleMenu()"></a>
		</div>
  </div>
</template>

<script>
import SvgImage from '@/components/SvgImage.vue'
//import { Component, Prop, Vue } from 'vue-property-decorator';
import { mapGetters, mapMutations } from 'vuex';
import { service } from '@/store/service'

export default {
	components: {
		SvgImage
	},

  props: {
  	fixed: Boolean
  },

  data() {
    return {
      menuActive: false,
      menuToggle: false,
      //notfixed: boolean = true;
      animate: [],
      animateDelay: 50,
      menuButtonShow: false,
      mvtnLogo: service.svg.mvtn.logo,
      unshadeLogo: false,
      //menuStyle = {};
    }
  },

	computed: {
		...mapGetters(['menu', 'menuOver', 'parallax', 'scrollWidth']),

		menuToggleComp() { return this.menuToggle; },

		style() {
			return {
				'--scroll-width': this.scrollWidth + 'px'
			}
		}
	},

	//created() { console.log('====', this.menu);	},

	mounted() {
		const _this = this;
		//console.log('menu mnt', (this as any).animate, window.innerWidth);
		_this.animate = _this.menu.map(() => false);
		_this.handleResize();
		window.addEventListener('resize', _this.handleResize);
	},

	watch: {
		$route(to, from) {
			const _this = this;
			_this.menuToggle = _this.menuActive = _this.unshadeLogo = false;
			_this.animate.fill(false);
		},

		toggleMenu(val) {
			console.log('tog', val);
		}
	},

  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },

  methods: {
		...mapMutations(['setMenuItemOver']),

    mOver(item, id) {
      //console.log('item over ' + JSON.stringify(item));
      this.setMenuItemOver({ name: item.name, data: true });
    },

    mOut(item, id) {
      //console.log('item out ' + item.over);
      this.setMenuItemOver({ name: item.name, data: false });
    },

    toggleMenu() {
      console.log('toggleMenu', this.$refs.menu.offsetHeight, window.innerHeight);

      this.menuActive = !this.menuActive;
      setTimeout(() => { this.unshadeLogo = !this.unshadeLogo; }, 300);
      
      const effect = this.getRandomInt(2);

      if (this.menuActive) {
        this.menuToggle = !this.menuToggle;
        //this.notfixed = !this.notfixed;
        //this.$emit('menuToggle', this.menuActive);
        //this.menuStyle = { height: window.innerHeight + 'px' };

        for (let i = 0; i < this.animate.length; i++) {
          setTimeout(() => {
            if (effect === 0) this.animate[this.animate.length - i - 1] = this.menuActive;
            else this.animate[i] = this.menuActive;
            //console.log(i + ' item: ' + (this as any).animate);
            this.$forceUpdate();
          }, this.animateDelay * i);
        }
      }
      else {
        setTimeout(() => { this.menuToggle = !this.menuToggle; }, this.animateDelay * (this.animate.length - 1));
        /*setTimeout(() => {
          this.notfixed = !this.notfixed;
          //this.$emit('menuToggle', this.menuActive);
          //this.menuStyle = { height: 4 + 'rem' }
        }, this.animateDelay * (this.animate.length - 1) + 300);*/
        
        for (let i = 0; i < this.animate.length; i++) {
          setTimeout(() => {
            this.animate[this.animate.length - i - 1] = this.menuActive;
            //console.log(i + ' item: ' + (this as any).animate);
            this.$forceUpdate();
          }, this.animateDelay * i);
        }
      }
    },

    handleResize() {
      this.menuButtonShow = window.innerWidth <= service.menuVisibleWidth ? true : false;
    },

    getRandomInt(max) {
      return Math.floor(Math.random() * Math.floor(max));
    }

    /*animate(index: number) {
      setTimeout(function() {
        console.log('item: ' + index);
        return true;
      }, 300 * index);
      //return true;
    }*/
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
@import '../styles/vars.less';

.menubar {
	//position: relative;
  width: calc(100% - var(--scroll-width));
  height: 4rem;
	background-color: @colorBgMenubar;
	flex-wrap: wrap;
	z-index: 1000;
	.mvtn-transition(height 0.3s);
	//.mvtn-transition-all(0.3s);

	@media @phone { align-items: flex-start; }

	&:hover {
		.logobox {
			.logoitem { .mvtn-transform(none); }
		}
	}

	&.active {
		height: 100%;
	
		.menu {
			display: flex;
			overflow-y: auto;

			.link {
				font-size: 3.3em;
				font-weight: 200;

				&.router-link-exact-active {
					//font-size: 3.3em;
					font-weight: 300;
					//color: @colorLinkHover;
				}
			}
		}

		.icons {
			.link:not(.menu-button) { margin-top: -100px; }

			.menu-button {
				&::before {	transform: rotate(-45deg) translate(-3px, 3px);	}
				&::after { transform: rotate(-135deg) translate(2px, 2px); }
			}
		}
	}

	.menu {
		margin: 0;
		padding: 0 15px;
		height: 100%;
		list-style: none;
		align-items: center;
		font-size: 0.95em;
		justify-content: flex-end;
		flex: 1;

		.menu-phone-tablet() {
			display: none;
			order: 3;
			width: 100%;
			height: calc(100% - 55px);
			flex-direction: column;
			justify-content: flex-start;
			flex-basis: 100%;
			padding-top: 1.5rem;
			//margin-top: 15px;
		}

		@media @tablet { .menu-phone-tablet();	}
		@media @phone {	.menu-phone-tablet(); }

		.link {
			color: @colorLink;
			text-decoration: none;
			.mvtn-transition-all();

			.link-phone-tablet() {
				.mvtn-transition-menu(0.25s);
				transform: scale(0.1);
				&:not(:first-of-type) {	margin-top: -6rem; }
				opacity: 0.5;
			}

			@media @tablet { .link-phone-tablet(); }
			@media @phone { .link-phone-tablet(); }

			&.active, &:hover {
				color: @colorLinkHover;
				cursor: pointer;
			}

			&.animate {
				transform: none;
				margin-top: 0;
				opacity: 1;
			}

			&.router-link-exact-active {
				font-size: 3.3em;
				font-weight: 200;
				color: @colorLinkHover;
			}
		}

		.page-footer {
			flex: 1;
			align-items: flex-end;
			padding: 1.5rem 0;
		}
	}

	.icons {
		height: 100%;
		align-items: center;
		padding: 0 10px;

		.phone-tablet() {
			height: 4rem;
			//order: 3;
		}
		@media @phone {	.phone-tablet(); }
		@media @tablet { .phone-tablet(); }

		.link {
			color: @colorLink;
			margin: 0 5px;
			font-size: 1.5em;
			.mvtn-transition-all();

			&.active, &:hover {
				color: @colorLinkHover;
				cursor: pointer;

				&:not(.menu-button) { transform: rotate(90deg); }
			}
		}
	}

	.logobox {
		height: 4rem;

		.phone-tablet() {
			margin-left: 1rem;
			//order: 2;
		}
		@media @phone {	.phone-tablet(); }
		@media @tablet { .phone-tablet(); }

		.logoitem {
			width: 42px;
			height: 40px;
			display: block;
			float: left;
			background: url(../assets/movitone05.svg) no-repeat;
			background-size: 400px auto;
			.mvtn-transition(transform 0.5s);

			@media @phone {
			 	width: 21px;
			 	height: 20px;
			 	background-size: 200px auto;
			}

			@media @tablet {
			 	width: 31.5px;
			 	height: 30px;
			 	background-size: 300px auto;
			}
		}

		.logo-m {
			background-position: 0 0;
			width: 52px;
			.mvtn-transform(rotate(-90deg) scaleX(0.8) translateY(5px));
			@media @phone {
			 	width: 26px;
			 	.mvtn-transform(rotate(-90deg) scaleX(0.8) translateY(2.5px));
			}
			@media @tablet {
			 	width: 39px;
			 	.mvtn-transform(rotate(-90deg) scaleX(0.8) translateY(2.5px));
			}
		}
		.logo-o {
			background-position: -53px 0;
			@media @phone {	background-position: -26.5px 0; }
			@media @tablet {	background-position: -39.75px 0;	}
		}
		/*.logo-o2 {
			.logo-o();
			margin-left: 0px;
		}*/
		.logo-v {
			background-position: -96px 0;
			.mvtn-transform(rotate(-90deg));
			@media @phone {	background-position: -48px 0; }
			@media @tablet {	background-position: -72px 0; }
		}
		.logo-i {
			background-position: -160px 0;
			width: 10px;
			.mvtn-transform(rotate(-90deg) scaleY(0.13));
			@media @phone {
			 	width: 6px;
			 	background-position: -80px 0;
			}
			@media @tablet {
			 	width: 9px;
			 	background-position: -120px 0;
			}
		}
		.logo-t {
			width: 44px;
			background-position: -193px 0;
			.mvtn-transform(rotate(-90deg));
			@media @phone {
			 	width: 22px;
			 	background-position: -96.5px 0;
			}
			@media @tablet {
			 	width: 33px;
			 	background-position: -144.75px 0;
			}
		}
		.logo-n {
			background-position: -237px 0;
			.mvtn-transform(rotate(-90deg));
			@media @phone {	background-position: -118.5px 0; }
			@media @tablet {	background-position: -177.75px 0; }
		}
		.logo-e {
			background-position: -280px 0;
			width: 42px;
			.mvtn-transform(rotate(-90deg) scaleY(1.25) translateY(5px));
			@media @phone {
			 	width: 21px;
			 	background-position: -140px 0;
			 	.mvtn-transform(rotate(-90deg) scaleY(1.25) translateY(2.5px));
			}
			@media @tablet {
			 	width: 31.5px;
			 	background-position: -210px 0;
			 	.mvtn-transform(rotate(-90deg) scaleY(1.25) translateY(2.5px));
			}
		}
	}
}

.menu-button {
	width: 21px;
	height: 20px;

	&::after,
	&::before {
		content: '';
		width: 100%;
		height: 2px;
		display: block;
		background-color: @colorLink;
		margin-top: 5px;
		.mvtn-transition-all-bezier(0.25s);
	}

	&:hover {
		&::before, &::after { background-color: @colorLinkHover; }
	}
}

</style>
