<template>
  <div :class="['flip-card', { vertical: option.vertical, card: option.card }]">
    <div class="flip-card-inner">
      <div class="flip-card-front flex centerv">
        <!--img src="img_avatar.png" alt="Avatar" style="width:300px;height:300px;"-->
        <img class="img" :src="require(`@/assets/${image.src}`)" />
        <div v-if="image.name" class="title">{{ image.name }}</div>
      </div>
      <div class="flip-card-back">
        <h1>MOVITONE</h1>
        <p>FRAMEE</p>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapMutations } from 'vuex';

export default {
  name: 'FlipCard',

  components: {},

  props: {
    image: Object,
    option: Object
  },

  methods: {
    ...mapMutations(['setPageTopImageHeight']),

  }

  
}
</script>

<style scoped lang="less">
@import '../styles/vars.less';

.flip-card {
  position: relative;
  z-index: 100;
  background-color: transparent;
  //width: 300px;
  //height: 300px;
  //border: 1px solid #f1f1f1;
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
  flex: 1;

  &:hover {
    .flip-card-inner { transform: rotateY(180deg); }
  }

  &.vertical {
    .flip-card-inner {
      .flip-card-back {
        transform: rotateX(180deg);
      }
    }

    &:hover {
      .flip-card-inner { transform: rotateX(180deg); }
    }
  }

  &.card {
    .flip-card-inner {
      .flip-card-front, .flip-card-back {
        border-radius: 15px;
      }
    }
  }

  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;

    .flip-card-front-back() {
      width: 100%;
      height: 100%;
      backface-visibility: hidden;
    }

    .flip-card-front {
      .flip-card-front-back();
      position: relative;
      //background-color: #bbb;
      background-image: linear-gradient(to bottom, #eee, #777);
      color: #fff;

      .title {
        width: 100%;
        position: absolute;
        //right: 10px;
        font-size: 2em;
        font-weight: 500;
        //backdrop-filter: blur(5px);
        background-color: #00000022;
        padding: 10px 15px;
        //border-radius: 10px;
      }
    }

    .flip-card-back {
      .flip-card-front-back();
      position: absolute;
      background-color: #777; //dodgerblue;
      color: white;
      transform: rotateY(180deg);
      top: 0;
    }
  }
}

</style>
