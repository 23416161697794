import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'
import Item from '../views/Item.vue'
import Tuurro from '../views/Tuurro.vue'
import Figuur from '../views/Figuur.vue'
import Framee from '../views/Framee.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/item',
    name: 'Item',
    component: Item,
    children: [
      {
        path: '/tuurro',
        name: 'Tuurro',
        component: Tuurro
      },
      {
        path: '/figuur',
        name: 'Figuur',
        component: Figuur
      },
      {
        path: '/framee',
        name: 'Framee',
        component: Framee
      }
    ]
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
