<template>
  <div
    :class="['images-container flex', { gap: gap }]"
    :style="{ '--item-width': itemWidth + '%', '--item-margin': -(itemWidth - 10) + '%' }"
    ref="images"
    :key="$route.name"
  >
    <!--div
      v-tscroll="handleScroll"
      :class="['image-item flex column scrollable']"
      v-for="(image, i) in images"
      :data-name="image.name"
      :key="i"
    -->
    <div
      v-scroll="{ threshold: 0.0 }"
      :class="['image-item flex column']"
      v-for="(image, i) in images"
      :data-name="image.name"
      :key="i"
    >
      <FlipCard v-if="flip" :image="image[activeImages[i]]" :option="{ vertical: flip.vertical, card: flip.card }" />
      <transition name="fade">
      <img v-if="!flip && image[activeImages[i]].src" class="img" :src="require(`@/assets/${image[activeImages[i]].src}`)" :key="keySalt[i]" />
      </transition>
      <div v-if="thumb" class="image-thumbs flex center">
        <span v-for="(thumb, j) in image" :key="i+j" class="image-thumb" @click="switchImage(i, j)">
          <img v-if="thumb.src" class="img" :src="require(`@/assets/${thumb.src}`)" />
        </span>
      </div>
      <div v-if="!flip" class="image-title footer" v-html="image[0].name"></div>
    </div>
  </div>
</template>

<script>
import FlipCard from '@/components/FlipCard.vue'
import { mapGetters, mapMutations } from 'vuex'
import { service } from '@/store/service'

export default {
  name: 'FlyingImages',

  components: {
    FlipCard
  },

  props: {
    images: Array,
    flip: Object,
    gap: Boolean,
    thumb: Boolean,
    offset: Number
  },

  data() {
    return {
      activeImages: [],
      keySalt: []
    }
  },

  computed: {
    ...mapGetters(['pageTopImageLoaded']),

    itemWidth() { return 100 / this.images.length; },
    vOffset() { return (this.offset === void(0) || this.offset === null) ? 150 : this.offset; }
  },

  watch: {
    pageTopImageLoaded(val, ov) {
      //console.log('val', val);
      this.$nextTick(() => {
        if (val) {
          if (this.$refs.images) {
            this.$refs.images.children.forEach(el => { service.handleScroll(el, 0, 150); });
            this.setFlyingImagesHeight(this.$refs.images.clientHeight);
          }
        }
      });
    }
  },

  created() {
    this.images.forEach(image => { this.activeImages.push(0); });
    //console.log('+--+--+', this.activeImages);
  },

  mounted() {
    this.$nextTick(() => {
      //console.log('handle scroll', this.$refs.images.children[0].getBoundingClientRect().top);
      if (this.$refs.images) this.$refs.images.children.forEach( el => { service.handleScroll(el, 0, this.vOffset); } );
    });
  },

  methods: {
    ...mapMutations(['setFlyingImagesHeight']),

    /*handleScroll(e, el, node) {
      //console.log('+++++', this.vOffset, this.offset, e, el, node);
      //console.log('handle scroll', this.$refs.images);
      return service.handleScroll(el, (2 - node.key) * 100, this.vOffset);
    },*/

    switchImage(i, j) {
      if (this.activeImages[i] === j) return;
      this.activeImages[i] = j;
      this.keySalt[i] = Date.now();
    }
  }
}

</script>

<style scoped lang="less">
@import '../styles/vars.less';

//.images-container::v-deep {
.images-container {
  width: 100%;

  @media @phone {
    flex-direction: column;
    overflow-x: hidden;
  }

  &.scroll-before-enter {
    //margin-left: -100%;
    .mvtn-transition-pos-bezier(0.7s);

    //&.scroll-enter { margin-left: 0; }
  }

  :deep(.image-item) {
    justify-content: flex-end;
    position: relative;

    &.scroll-before-enter {
      .mvtn-transition-all-bezier-deeep(0.7s, 1.8);
      transition-delay: 0.3s;
      width: var(--item-width);
      margin-left: var(--item-margin);
      transform: scale(0.5);

      @media @phone {
        width: 100%;
        margin-left: -75%;
      }

      &.scroll-enter {
        margin-left: 0;
        transform: none;
      }

      & + .scroll-before-enter {
        transition-delay: 0.15s;

        & + .scroll-before-enter {
          transition-delay: 0s;
        }
      }
    }

    .footer { padding-bottom: 2vw; }

    .image-thumbs {
      .image-thumb {
        width: 5vw;
        height: 5vw;
        cursor: pointer;
        .mvtn-transition(transform 0.5s);

        @media @phone {
          width: 20vw;
          height: 20vw;
        }

        &:hover {
          transform: scale(1.5);
        }
      }
    }

    .image-title {
      display: flex;
      justify-content: center;
      font-size: 3vw;
      padding-top: 1vw;
      color: #777;

      @media @phone {
        font-size: 10vw;
        padding-top: 2vw;
      }
    }
  }

  :deep(.img) { width: 100%; }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.75s ease;
}
.fade-enter-from, .fade-leave-to {
  opacity: 0;
  position: absolute;
  top: 0;
}

</style>