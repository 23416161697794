<template>
  <div :class="['tile', { active: tile.over }]" @mouseenter="mOver"  @mouseleave="mOut">
    <transition name="fade">
      <img v-if="tile.img" :src="require(`../assets/${tile[image]}`)" :key="image" />
    </transition>
    <div :class="['content', { active: tile.over }]">
      <div class="header">
        <h3 class="h3" v-html="tile.name"></h3>
        <h4 class="h4" v-html="tile.title"></h4>
      </div>
      <dl class="dlist" v-for="(item, index) in tile.description" :key="index">
        <dt class="dt">{{ item.title }}</dt>
        <dd class="dd">{{ item.text }}</dd>
      </dl>
    </div>
  </div>
</template>

<script>
//import { Component, Prop, Vue } from 'vue-property-decorator';
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'Tile',

  props: {
    tile: Object
  },

  data() {
    return {
      imgkey: 'img',
      active: false
    }
  },

  /*watch: {
    menuOver(newValue, oldValue) {
      //console.log(`Updating menuOver from ${oldValue} to ${newValue}`);
      this.active = this.tile.over;
    }
  },*/

	computed: {
    ...mapGetters(['menu', 'menuOver']),

    image() {
      return 'img';
      //return (this.tile.imgalt && this.tile.over) ? 'imgalt' : 'img';
    }
  },
  
  methods: {
    ...mapMutations(['setMenuItemOver']),

    mOver() {
      //this.setMenuItemOver({ id: this.id, data: true });
      this.tile.over = true;
    },

    mOut() {
      //this.setMenuItemOver({ id: this.id, data: false });
      this.tile.over = false;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
@import '../styles/vars.less';

@colorBgContent: rgba(0, 0, 0, 0.25);
@colorBgContentHover: rgba(0, 0, 0, 0);

.tile {
  width: 100%;
  height: 100%;
  background-color: @colorBgMain;
  position: relative;
  overflow: hidden;

  &.active {
    img {
      transform: scale(2);
      //object-fit: cover;
    }
  }

  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    position: absolute;
    .mvtn-transition(transform 1s);
  }

  .content {
    color: @colorTileText;
    //background-color: @colorBgContent;
    opacity: 0.55;
    height: 100%;
    width: 100%;
    position: relative;
    padding: 0;
    box-sizing: border-box;
    font-family: Roboto;
    overflow: hidden;
    .mvtn-transition-all();

    &.active,
    &:hover {
      //background-color: @colorBgContentHover;
      opacity: 1;
    }

    :deep(.header) {
      padding: 10px 15px;

      .mark { font-style: normal; }

      .h3 {
        .hhh();
        font-size: 2em;
        font-weight: 200;

        @media @phone {
          font-size: 1.25em;
        }
      }

      .h4 {
        .hhh();
        font-size: 0.9em;
        color: @colorSubTileText;
      }
    }

    .dlist {
      margin: 0;
      padding: 10px 15px;
      background-color: @colorMainDark;

      @media @phone {
        display: none;
      }

      .dt {
        padding: 5px 0 10px 0;
        font-size: 1.5em;
        font-weight: 200;
        color: @colorSubTileText;
      }

      .dd {
        margin: 0;
        font-size: 1em;
        color: @colorDescriptionText;
      }
    }
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.75s ease;
}
.fade-enter-from, .fade-leave-to {
  opacity: 0;
}

</style>
