import { tuurro } from './tuurro'
import { figuur } from './figuur'
import { framee } from './framee'

const tiles = {
  tuurro: tuurro.tile,
  figuur: figuur.tile,
  framee: framee.tile,

  // PLAANO
  plaano: {
    name: 'PLAANO',
    url: '/plaano',
    title: 'Invisible speakers',
    wmsize: 30,
    //img: 'plaano/plaano01.png',
    description: [
      {
        tite: "Invisible?",
        text: "Yes, but we don't break any law of physics. It is not magic; it is leading edge technology. While you can see the Plaano speakers when unpacking, after the installation they will become completely invisible."
      },
      {
        title: "How does it work?",
        tetx: "In general, to produce sound a speaker needs to move the air. Plaano speakers differ from traditional speakers in the way they produce sound. The flat surface of the Plaano bends to produce concentric waves like the ripples of water when a stone is dropped in a pond, instead of forcing the diaphragm membrane to move up and down like a piston."
      },
      {
        title: "Design.",
        text: "Plaano speakers can be applied to any surface and will meet any interior design challenge. They can be covered, painted, or embedded in any material to fit your decor."
      },
      {
        title: "Benefits.",
        text: "In addition to invisibility and endless design possibilities, Plaano has an extremely wide dispersion of nearly 180"
      }
    ],
    over: false
  },

  // KADDRO
  kaddro: {
    name: 'KADDRO',
    url: '/kaddro',
    title: 'Bold sound in slim',
    wmsize: 30,
    img: 'kaddro/kaddro01.png',
    over: false
  },

  // SFERRO
  sferro: {
    name: 'SFERRO',
    url: '/sferro',
    title: 'Round and loud',
    wmsize: 30,
    img: 'sferro/sferro01.png',
    over: false
  }
};

const svg: any = {
  mvtn: {
    logo: {
      id: 'mvtn',
      x: '0px',
      y: '0px',
      viewBox: '0 0 1027 567',
      style: 'fill:none; stroke-width:70; stroke-miterlimit:10;',
      g: `<polyline class="st0" points="991.5,9.6 870.2,455.2 743.2,129.1 623.1,455.2 502.7,44.1 107.2,163.6 412.9,284.2 107.2,404.7 532.2,533.2 "/>`
    }
    /*<svg width="1027" height="567" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve">
    <style type="text/css">
      .st0{fill:none;stroke:#FF0000;stroke-width:70;stroke-miterlimit:10;}
      .st1{fill:none;stroke:#000000;stroke-miterlimit:10;}
    </style>
    <polyline class="st0" points="991.5,9.6 870.2,455.2 743.2,129.1 623.1,455.2 502.7,44.1 107.2,163.6 412.9,284.2 107.2,404.7 
      532.2,533.2 "/>
    <path class="st1" d="M1133.9,566.9"/>
    <path class="st1" d="M1133.9,0"/>
    </svg>*/
    
  },

  figuur: {
    figuurA: {
      id: 'figuurA',
      name: 'Figuur A',
      x: '0px',
      y: '0px',
      viewBox: '0 0 150 150',
      style: 'fill:none; stroke:#FFFFFF; stroke-width:2; stroke-linecap:round; stroke-linejoin:round; stroke-miterlimit:10;',
      g: `<line class="st0" x1="122.9" y1="11.3" x2="31.1" y2="28.7"/>
          <line class="st0" x1="31.1" y1="28.7" x2="13.4" y2="137.7"/>
          <line class="st0" x1="13.4" y1="137.7" x2="134.6" y2="137.7"/>
          <line class="st0" x1="134.6" y1="137.7" x2="122.9" y2="11.3"/>`
    },
    figuurC: {
      id: 'figuurC',
      name: 'Figuur C',
      x: '0px',
      y: '0px',
      viewBox: '0 0 150 150',
      style: 'fill:none; stroke:#FFFFFF; stroke-width:2; stroke-linecap:round; stroke-linejoin:round; stroke-miterlimit:10;',
      g: `<line class="st0" x1="139.4" y1="138.7" x2="121.7" y2="29.7"/>
          <line class="st0" x1="10.6" y1="138.7" x2="139.4" y2="138.7"/>
          <line class="st0" x1="121.7" y1="29.7" x2="28.3" y2="29.7"/>
          <line class="st0" x1="10.6" y1="138.7" x2="28.3" y2="29.7"/>`
    },
    figuurB: {
      id: 'figuurB',
      name: 'Figuur B',
      x: '0px',
      y: '0px',
      viewBox: '0 0 150 150',
      style: 'fill:none; stroke:#FFFFFF; stroke-width:2; stroke-linecap:round; stroke-linejoin:round; stroke-miterlimit:10;',
      g: `<line class="st0" x1="118" y1="29.7" x2="26.1" y2="12.3"/>
          <line class="st0" x1="135.6" y1="138.7" x2="118" y2="29.7"/>
          <line class="st0" x1="14.4" y1="138.7" x2="135.6" y2="138.7"/>
          <line class="st0" x1="26.1" y1="12.3" x2="14.4" y2="138.7"/>`
    }
  },

  framee: {
    framee24: {
      id: 'framee41',
      name: 'Framee.41',
      x: '0px',
      y: '0px',
      viewBox: '0 0 150 150',
      style: 'fill:none; stroke:#FFFFFF; stroke-width:2; stroke-linecap:round; stroke-linejoin:round; stroke-miterlimit:10;',
      g: `<rect x="53" y="42" width="44" height="44"/><rect x="60" y="49" width="30" height="30"/>`
    },
    framee26: {
      id: 'framee42',
      name: 'Framee.42',
      x: '0px',
      y: '0px',
      viewBox: '0 0 150 150',
      style: 'fill:none; stroke:#FFFFFF; stroke-width:2; stroke-linecap:round; stroke-linejoin:round; stroke-miterlimit:10;',
      g: `<rect x="53" y="25" width="44" height="82"/><rect x="60" y="32" width="30" height="68"/>`
    },
    framee28: {
      id: 'framee43',
      name: 'Framee.43',
      x: '0px',
      y: '0px',
      viewBox: '0 0 150 150',
      style: 'fill:none; stroke:#FFFFFF; stroke-width:2; stroke-linecap:round; stroke-linejoin:round; stroke-miterlimit:10;',
      g: `<rect x="53" y="7" width="44" height="120"/><rect x="60" y="14" width="30" height="106"/>`
    },
    framee102: {
      id: 'framee102p',
      name: 'Framee.102P',
      x: '0px',
      y: '0px',
      viewBox: '0 0 150 150',
      style: 'fill:none; stroke:#FFFFFF; stroke-width:2; stroke-linecap:round; stroke-linejoin:round; stroke-miterlimit:10;',
      g: `<rect x="53" y="42" width="44" height="44"/><rect x="62" y="51" width="26" height="26"/>`
    },
    framee101p: {
      id: 'framee102p',
      name: 'Framee.102P',
      x: '0px',
      y: '0px',
      viewBox: '0 0 150 150',
      style: 'fill:none; stroke:#FFFFFF; stroke-width:2; stroke-linecap:round; stroke-linejoin:round; stroke-miterlimit:10;',
      g: `<rect x="57" y="47" width="35" height="35"/><rect x="66" y="56" width="17" height="17"/>`
    },
    framee101s: {
      id: 'framee102p',
      name: 'Framee.102P',
      x: '0px',
      y: '0px',
      viewBox: '0 0 150 150',
      style: 'fill:none; stroke:#FFFFFF; stroke-width:2; stroke-linecap:round; stroke-linejoin:round; stroke-miterlimit:10;',
      g: `<rect x="59" y="49" width="31" height="31"/><rect x="68" y="58" width="13" height="13"/>`
    }
  }
}

const handleScroll = (el: any, delay: number, offset: number) => {
  //if (window.scrollY > 150) {
  //console.log(window.innerHeight, el.getBoundingClientRect().top, el);
  if (window.innerHeight > el.getBoundingClientRect().top + offset) {
    setTimeout(() => { el.classList.add('scrolled'); }, delay);
    //el.classList.add('scrolled');
  }
  else {
    if (el.classList.contains('scrolled')) setTimeout(() => { el.classList.remove('scrolled'); }, delay); //el.classList.remove('scrolled');
  }
  return false; //(window.innerHeight > (el.getBoundingClientRect().top + 100) && last) ? true : false; //window.scrollY > 200
}

const pageTop = {
  colorWM: '#ffffff07',
  colorHeader: '#ffffff18',
}

const createThresholds = (numSteps: number) => {
  let thresholds = [];

  for (let i = 1.0; i <= numSteps; i++) {
    //let ratio = i/numSteps;
    thresholds.push(i/numSteps);
  }

  thresholds.push(0);
  return thresholds;
}

export const service = {
  tiles,
  handleScroll,
  pageTop,
  menuVisibleWidth: 980,
  phoneMaxWidth: 740,
  svg,
  createThresholds
}
