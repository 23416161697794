import { createStore } from 'vuex'

/*export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})*/

//import Vue from 'vue'
//import Vuex from 'vuex'
import { service } from './service'

//Vue.use(Vuex)

const menubar = {
  namespaced: true,

  state: {
    //menu: [
    //  { name: 'PLAANO', over: false },
    //  { name: 'TUURRO', over: false },
    //],
    // menuOver: false
  },
  getters: {
    // menu: (state: any) => { return state.menu; },
    // menuItemByName: (state: any) => (name: string) => {
    //   return state.menu.find((item: any) => item.name === name);
    // },
    // menuItemById: (state: any) => (id: number) => { console.log(state.menu[id]); return state.menu[id]; },
    // menuOver: (state: any) => { return state.menuOver; }
  },

  mutations: {
    // setMenuItemOver(state: any, val: any) {
    //   let item = state.menu[val.id];
    //   item.over = val.data;
    //   state.menuOver = !state.menuOver;
    // },
    // toggleMenuOver(state: any) { state.menuOver = !state.menuOver; }
  },
  actions: {
  },
  modules: {
  }
}

export default createStore({
  state: {
    tiles: service.tiles,
    menuOver: false,
    isMetric: true,
    pageTop: {
      full: false,
      image: false,
      imageHeight: null,
      wmHeight: null
    },
    flyingImagesHeight: 0,
    pageTopImageLoaded: false,
    parallax: false,
    scrollWidth: 0
  },
  getters: {
    menu: (state: any) => { return Object.values(state.tiles).map((t: any) => {
      //console.log(t);
      return { name: t.name, url: t.url, over: t.over } });
    },
    animate: (state: any) => { return state.tiles.map(() => false) },
    //tile: (state: any) => (name: string) => { return state.tiles[name.toLowerCase()]; },
    tileByName: (state: any) => (name: string) => { return state.tiles[name.toLowerCase()]; },
    specsData: (state: any, getters) => (name: string, type: string) => {
      const tspec = getters.tileByName(name).specs[type];
      const head = [{ name: '' }, ...tspec.items.map((item: any) => { return { name: item.name, image: item.image, position: item.position } })];

      let spec: any = {};
      Object.keys(tspec.head).forEach(k => {
        //let line = [tspec.head[k]];
        let line = [
          tspec.head[k].hasOwnProperty('title')
            ? `<span class="flex">${tspec.head[k].name} <i class="r-icon ml" title="${tspec.head[k].title}">?</i></span>`
            : tspec.head[k]
        ];
        tspec.items.forEach((item: any) => {
          line = [
            ...line,
            item[k]
              ? item[k].hasOwnProperty('title') ? `<span class="flex">${item[k].name} <i class="r-icon ml" title="${item[k].title}">?</i></span>` : item[k]
              : '&#x2012'
          ];
        });
        spec[k] = line;
      });

      return { head, spec, type: tspec.type };
    },
    specsNote: (state: any, getters) => (name: string) => {
      const tnote = getters.tileByName(name).specs.note;
      return tnote ? tnote : { items: null };
    },
    menuOver: (state: any) => { return state.menuOver; },
    isMetric: (state: any) => { return state.isMetric; },
    isPhone: (state: any) => { return window.innerWidth <= service.phoneMaxWidth; },
    isVertical: (state: any) => { return window.innerHeight > window.innerWidth; },
    pageTopImageLoaded: (state: any) => { return state.pageTopImageLoaded; },
    pageTopImage: (state: any) => { return state.pageTop.image; },
    pageTop: (state: any) => { return state.pageTop; },
    textContainerSize: (state: any, getters) => {
      //return getters.pageTop.full       ?
      return {
        height: (window.innerHeight < getters.pageTop.imageHeight ? window.innerHeight : getters.pageTop.imageHeight) - getters.pageTop.wmHeight,
        width: document.body.clientWidth
      };
    },
    flyingImagesHeight: (state: any) => { return state.flyingImagesHeight; },
    parallax: (state: any) => { return state.parallax; },
    scrollWidth: (state: any) => { return state.scrollWidth; }
  },
  mutations: {
    setMenuItemOver(state: any, val: any) {
      state.tiles[val.name.toLowerCase()].over = val.data;
      state.menuOver = !state.menuOver;
      //console.log(state.tiles[val.id].over);
    },
    toggleMetricImperial(state: any) { state.isMetric = !state.isMetric; },
    setPageTop(state: any, val: any) {
      //console.log('val', val);
      Object.keys(val).forEach(k => { state.pageTop[k] = val[k]; });
      //[state.pageTop.wmHeight, state.pageTop.full] = Object.values(val);
      //console.log('state.pageTop', state.pageTop);
    },
    setPageTopImageHeight(state: any, height: number) {
      state.pageTop.imageHeight = height;
      state.pageTopImageLoaded = !!height;
    },
    setFlyingImagesHeight(state: any, val: number) { state.flyingImagesHeight = val },
    //setPageTopImageLoaded(state: any, loaded: boolean) { state.pageTopImageLoaded = true; }
    setParallax(state: any, p: boolean) { state.parallax = p; },
    setScrollWidth(state: any, width: number) { state.scrollWidth = width; }
  },
  actions: {
  },
  modules: {
    menubar
  }
})
