const defaultOptions = {
  //root: null,
  //rootMargin: '0px',
  threshold: 0.9,
};

const defaultThreshold = 0.9;
const defaultClassName = 'scroll-before-enter';
//let prevRatio = 0.0;

const createScrollObserver = (options, trigger) => new IntersectionObserver((entries, scrollObserver) => {
  entries.forEach((entry) => {
    let set = trigger
      ? entry.intersectionRect.height > (entry.boundingClientRect.height * defaultThreshold) || entry.boundingClientRect.bottom < window.innerHeight
      : entry.isIntersecting;
    //console.log(entry.target, entry, window.innerHeight, options, entry.target.getBoundingClientRect());
    /*if (trigger) {
      //console.log(entry, window.innerHeight, options, entry.target.getBoundingClientRect());
      //console.log((entry.intersectionRect.height < (entry.boundingClientRect.height * defaultThreshold)), (entry.boundingClientRect.bottom < window.innerHeight));
      //set = entry.rootBounds === null
      //  ? entry.target.getBoundingClientRect().bottom < window.innerHeight
      //  : entry.intersectionRect.height > (entry.boundingClientRect.height * defaultThreshold) || entry.boundingClientRect.bottom < window.innerHeight;
      set = entry.intersectionRect.height > (entry.boundingClientRect.height * defaultThreshold) || entry.boundingClientRect.bottom < window.innerHeight;
    }*/
    //if (set) scrollObserver.unobserve(entry.target);
    set ? entry.target.classList.add('scroll-enter') : entry.target.classList.remove('scroll-enter');
  });
}, options);


export default {
  beforeMount(el, binding, vnode, pnode) {
    //console.log(binding.value);
    let options = { threshold: defaultThreshold };
    let className = defaultClassName;
    //let trigger = !!binding.value.trigger;

    if (binding.value) {
      if (binding.value.className) className = binding.value.className;
      if (binding.value.threshold !== void(0)) {
        if (binding.value.threshold === -1) trigger = true;
        else if (binding.value.threshold >= 0.0 || Array.isArray(binding.value.threshold)) options.threshold = binding.value.threshold;
      }
    }

    //if (binding.value) Object.keys(binding.value).filter(v => v !== 'className').forEach(v => options[v] = binding.value[v]);
    //console.log(options, className, el);
    el.classList.add(className);
    const scrollObserver = createScrollObserver(options, !!binding.value.trigger);
    //console.log(options, className, scrollObserver, el);
    scrollObserver.observe(el);
  }
}