
import { defineComponent } from 'vue';

export default {
  name: 'HelloWorld',
  props: {
    msg: String,
  },
};

/*export default defineComponent({
  name: 'HelloWorld',
  props: {
    msg: String,
  },
});*/
