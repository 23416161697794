<template>
  <div class="spec-table-container flex column grow">
    <div class="type-container flex">
      <div class="type flex centerv">{{ specs.type }}</div>
      <!--span class="btn" @click="toggleSpecs">open</span-->
    </div>
    <!--VTransition-->
      <div class="spec-table flex column">
        <div class="row flex head">
          <div
            :class="['cell flex column grow', { 'title': i === 0 }, { 'centerh': center }]"
            v-for="(h, i) in specs.head"
            :key="i"
            :style="style(specs.spec.dimension[i], h.position)"
          >
            <div :class="['name flex', { 'centerh': center }]">{{ h.name }}</div>
            <!--img class="svg" v-if="h.image" :src="require(`../assets/${h.image}`)" /-->
            <SvgImage v-if="h.image" :svg="getImage(h.image)" class="svg" />
            <transition name="fade">
              <div v-if="h.image" :class="['dimension', className]" :key="cellKey(specs.spec.dimension[i])"></div>
            </transition>
          </div>
        </div>
        <div v-for="(row, index) in specs.spec" :key="index" class="row flex">
          <div v-for="(cell, idx) in row" :key="idx" :class="['cell flex column grow centerh', {'title': idx === 0}]">
            <transition name="slide">
              <div :class="['cell-inner flex centerv', { 'centerh': (center && idx !== 0) }]" :key="cellKey(cell)">
                <div v-html="cellData(cell, idx === 0)" class="cell-data flex column"></div>
              </div>
            </transition>
          </div>
        </div>
      </div>
    <!--/VTransition-->
  </div>
</template>

<script>
// @ is an alias to /src
//import VTransition from '@/components/VTransition.vue'
import SvgImage from '@/components/SvgImage.vue'
import { mapGetters, mapMutations } from 'vuex'
import { service } from '@/store/service'

export default {
  name: 'SpecTable',

  components: {
    SvgImage
  },

  props: {
    specs: {},
    center: Boolean
  },

  data() {
    return {
      //show: true
    }
  },

  computed: {
    ...mapGetters(['menu', 'isMetric']),

    className() { return this.isMetric ? 'metric' : 'imperial'; },
    //mvtnLogo() { return ''; }
    //referenceItems() { return this.specs.reference ? this.specs.reference.items : null; }
    //colStyle() { return {'--head-height': this.specs.headHeight}; }
  },

  mounted() {
    //console.log(this.specs.spec);
  },

  methods: {
    //...mapGetters(['tileByName']),

    cellData(cell, isHead) {
      //if (cell.metric) console.log('cell', cell, isHead, cell.metric ? true : false);
      const getHtml = (text, indent) => {
        let cn = isHead ? indent ? 'indent' : 'head name' : indent ? 'indent' : 'head';
        return `<span class="${cn}">${text}</span>`
      };

      let tcell = cell;
      if (cell.metric) tcell = this.isMetric ? cell.metric : cell.imperial;
      if (typeof(tcell) === typeof({})) {
        let subData = Object.keys(tcell).filter(c => c !== 'name').map(item => getHtml(tcell[item], true));
        return getHtml(tcell.name) + (subData.length > 0 ? subData.reduce((a, c) => a + c) : '');
      }

      return getHtml(tcell);
    },

    cellKey(cell) { return (cell && cell.metric) ? this.isMetric : 0; },

    //className(name) { return name.toLowerCase().split(' ').join('-') + ' ' + (this.isMetric ? 'metric' : 'imperial'); },

    style(dim, pos) {
      //if (pos.name === 'Framee 41') 
      if (pos && pos.h && pos.w) console.log('pos', pos, pos.h, pos.w);
      if (!dim.metric) return;
      const m = dim.metric.split(' x ');
      const i = dim.imperial.split(' x ');
      return {
        '--wm': `'${m[0]}mm'`,
        '--hm': "'" + m[1] + "mm'",
        '--wi': "'" + i[0] + "'",
        '--hi': "'" + i[1] + "'",
        '--dim-w-bot': pos && pos.w ? pos.w.bot: '2rem',
        //'--dim-w-top': pos && pos.w ? pos.w.top: 'unset',
        '--dim-h-bot': pos && pos.h ? pos.h.bot: '7rem',
        '--dim-h-top': pos && pos.h ? pos.h.top: 'unset'
      }
    },

    getImage(img) {
      if (!img) return;
      console.log(img);
      const keys = img.split('/');
      console.log(service.svg[keys[0]][keys[1]]);
      return service.svg[keys[0]][keys[1]];
    }

    //toggleSpecs() { this.show = !this.show; }
  }
}
</script>

<style scoped lang="less">
@import '../styles/vars.less';

.spec-table-container {
  .type-container {
    .type-container();
  }

  .spec-table {
    color: @colorTable;
    //padding: 0 15px;
    min-width: 700px;
    overflow-x: auto;

    .row {
      border-bottom: 1px solid @colorTableBorder;
      box-sizing: border-box;
      opacity: 0.6;
      .mvtn-transition-all();

      &.head {
        height: var(--head-height);
        //border-top: 1px solid @colorSemiWhite;
        padding: 7px 0;

        //.name { justify-content: center; }
      }

      &:hover {
        //background-color: @colorRowHover;
        opacity: 1;
      }

      :deep(.cell) {
        //height: var(--ih); //17.5rem;
        position: relative;
        min-width: 18rem;
        //display: inline-block;

        &.title { min-width: 30rem; }
        //&.center { text-align: center; }
        .svg { height: 15rem; }

        .dimension {
          //display: block;
          font-size: 0.8em;

          .before(@arg) {
            content: var(@arg);
            position: absolute;
            bottom: var(--dim-w-bot); //2rem;
            top: var(--dim-w-top);
            width: 100%;
            text-align: center;
          }

          .after(@arg) {
            content: var(@arg);
            position: absolute;
            bottom: var(--dim-h-bot); //7rem;
            top: var(--dim-h-top);
            width: 100%;
            text-align: center;
            transform: rotate(270deg);
          }

          &.imperial {
            &::before { .before(--wi); }
            &::after {
              .after(--hi);
              //left: 9rem;
            }
          }

          &.metric {
            &::before { .before(--wm); }
            &::after {
              .after(--hm);
              //left: 9rem;
            }
          }

          &.fade-enter-active/*, &.fade-leave-active*/ {
            //.mvtn-transition(opacity 0.75s ease);
            .mvtn-transition-all(1.0s);
          }
          &.fade-enter-from, &.fade-leave-to {
            opacity: 0;
            //margin-top: -2.5rem;
          }
        }

        .cell-inner {
          //min-height: 40px;
          padding: 5px 0;

          .cell-data {
            cursor: default;

            .head {
              padding: 2px 0;
              &.name { color: @colorTableBright; }
            }

            .indent {
              display: flex;
              font-size: 0.9em;
              padding: 2px 0 2px 15px;
            }
          }

          .r-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            font-style: normal;
            font-weight: 800;
            font-size: 0.75em;
            //line-height: 2;
            background-color: @colorSemiWhite;
            color: @colorBgMain;
            //padding: 0 5px;
            margin-right: 5px;
            width: 20px;
            height: 20px;
            //min-width: 20px;
            border-radius: 999px;

            &:hover { cursor: default; }
            &.ml { margin-left: 5px; }
          }
        }

        .slide-enter-active, .slide-leave-active {
          //.mvtn-transition(opacity 0.75s ease);
          .mvtn-transition-all(0.5s);
          position: absolute;
          width: 100%;
        }
        .slide-enter-from, .slide-leave-to {
          opacity: 0;
          margin-top: -2.5rem;
        }
      }
    }
  }
}

</style>
