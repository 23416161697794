<template>
  <!--img v-load="handleLoad" class="image" :src="require(`@/assets/${image}`)" ref="image" /-->
  <img @load="onImageLoad" :class="['image', { vertical: vertical }]" :src="require(`@/assets/${image}`)" ref="image" />
</template>

<script>
// @ is an alias to /src
import Menubar from '@/components/Menubar.vue'
import { mapMutations } from 'vuex';

export default {
  name: 'PageTopImage',

  components: {},

  props: {
    image: String,
    vertical: Boolean
  },

  methods: {
    ...mapMutations(['setPageTopImageHeight']),

    //handleLoad(e, el, node) { console.log('PageTopImage Load', this.$refs.image.clientHeight); },
    onImageLoad(e) {
      this.setPageTopImageHeight(this.$refs.image.clientHeight);
      //this.setPageTopImageLoaded(true);
    }
  },

  /*directives: {
    load: {
      inserted(el, binding) {
        let f = (evt) => {
          if (binding.value(evt, el)) { window.removeEventListener('load', f); }
        }
        window.addEventListener('load', f);
      }
    }
  },*/
  
  beforeUnmount() {
    this.setPageTopImageHeight(null);
    //this.setPageTopImageLoaded(false);
  }
}
</script>

<style scoped lang="less">
@import '../styles/vars.less';

.image {
  position: fixed;
  width: 100%;

  &.vertical {
    height: 100%;
    width: auto;
    margin-left: 50%;
    transform: translate(-50%);
  }
}

</style>
