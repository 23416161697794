<template>
  <div class="content">
    <!--PageTop :name="tile.name" :title="tile.title" /-->
    <div :class="['text-container framee-bg', { 'fade': !loaded }]" :style="style" :key="tile.name">
      <div class="text-inner flex bottomv">
        <div v-tscroll="handleScrollDlist" :class="['dlist-container flex wrap', { dark: dlistDark }]">
        <!--div :class="['dlist-container flex wrap', { dark: dlistDark }]"-->
        <dl class="dlist" v-for="(item, index) in tile.description" :key="index" ref="dlist">
          <dt>{{ item.title }}</dt>
          <dd>{{ item.text }}</dd>
        </dl>
      </div>
      </div>
    </div>
    <div :class="['text-container', { dark: dlistDark }]" :key="tile.name + '1'">
      <!--img class="img-wide" src="@/assets/framee/framee01.jpg" /-->
      <!--div class="image-container flex gap" ref="images">
        <div v-tscroll="handleScrollImage" class="image-item flex column scrollable" v-for="(img, i) in tile.images" :data-name="img.name" :key="i">
          <SvgImage :svg="img" /-->
          <!--FlipCard :image="img" :option="{ vertical: true, card: true }" />
          <img class="img" :src="require(`@/assets/${img.src}`)" />
          <span class="img-title" v-html="img.name"></span-->
        <!--/div>
      </div-->
      <FlyingImages :images="tile.images" :gap="true" :flip="{ vertical: true, card: true }" />

      <div class="title-container backdrop-blur flex space" ref="specs">
        <div class="title" @click="toggleShowSpecs()">Specifications</div>
        <div class="btn-container flex centerv gap">
          <div :class="['btn specs', { shaded: !showSpecs }]" @click="toggleMetricImperial()">{{ btnMetricImperial }}</div>
          <div :class="['btn icon', { active: showSpecs }]" @click="toggleShowSpecs()" :title="showSpecsTitle">
            <font-awesome-icon icon="chevron-right" />
          </div>
        </div>
      </div>
      <!--div class="image-container flex">
        <FlipCard v-for="(img, i) in tile.images" :key="i" :image="img" :vertical="true" />
      </div-->
      <!--div :class="['page-footer red', { active: showSpecs }]"></div-->
      <VTransition>
        <div v-if="showSpecs" class="specs-container backdrop-blur flex column">
          <div class="bg-img-container"></div>
          <div class="specs-inner flex wrap gap">
            <SpecTable :specs="specsData()('framee', 'satellites')" :center="true" />
            <SpecTable :specs="specsData()('framee', 'subwoofers')" />
            <!--SpecTable :specs="specsData()('figuur', 'amplifiersd')" /-->
            <!--SpecTable :specs="specsData()('framee', 'amplifiersp')" /-->
          </div>
          <div class="note-container">
            <SpecNote :name="'framee'" />
          </div>
        </div>
      </VTransition>
      <div :class="['page-footer backdrop-blur force-blur red flex center', { active: showSpecs }]">
        <SvgImage :svg="mvtnLogo" />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import PageTop from '@/components/PageTop.vue'
import SpecTable from '@/components/SpecTable.vue'
import SpecNote from '@/components/SpecNote.vue'
import VTransition from '@/components/VTransition.vue'
import SvgImage from '@/components/SvgImage.vue'
//import FlipCard from '@/components/FlipCard.vue'
import FlyingImages from '@/components/FlyingImages.vue'
import { mapGetters, mapMutations } from 'vuex'
import { service } from '@/store/service'

export default {
  name: 'Framee',

  components: {
    PageTop,
    SpecTable,
    SpecNote,
    SvgImage,
    VTransition,
    SvgImage,
    //FlipCard,
    FlyingImages
  },

  data() {
    return {
      tile: this.tileByName()('framee'),
      loaded: false,
      showSpecs: false,
      //specImages: service.svg.figuur,
      mvtnLogo: service.svg.mvtn.logo,
      dlistDark: false
    }
  },

  computed: {
    ...mapGetters(['menu', 'isMetric', 'isPhone', 'pageTop', 'pageTopImageLoaded', 'textContainerSize']),

    btnMetricImperial() { return this.isMetric ? 'Imperial' : 'Metric'; },
    showSpecsTitle() { return this.showSpecs ? 'Hide specifications' : 'Show specifications'; },
    //textContainerHeight() { return (window.innerHeight < this.pageTop.imageHeight ? window.innerHeight : this.pageTop.imageHeight) - this.pageTop.wmHeight; },
    style() {
      return {
        '--tc-height': this.textContainerSize.height + 'px',
        '--tcwm-height': this.pageTop.wmHeight + 'px',
        '--vh': (window.innerHeight - this.pageTop.wmHeight) + 'px' //window.innerHeight * 0.01 + 'px'
        //'--tci-height': this.pageTop.imageHeight + 'px'
      }
    }
  },

  created() {
    //console.log('created', this.specsData()('figuur', 'satellites'));
    console.log('framee created');
    setTimeout(() => { this.loaded = true; }, 400);
  },

  mounted() {
    this.$nextTick(() => {
      //this.$refs.images.children.forEach(el => { service.handleScroll(el, 0); } );
      this.dlistDark = this.isPhone ? false : true;
      //console.log('framee refs', this.$root, this.$refs, this.$parent.$children[0].$refs.image.clientHeight);
    });
  },

  /*watch: {
    pageTopImageLoaded(val) {
      this.$nextTick(() => {
        if (val) this.$refs.images.children.forEach(el => { service.handleScroll(el, 0); });
      });
    }
  },*/

  methods: {
    ...mapGetters(['tileByName', 'specsData']),
    ...mapMutations(['toggleMetricImperial']),

    toggleShowSpecs() {
      this.showSpecs = !this.showSpecs;

      setTimeout(() => {
        if (this.$refs.specs.offsetTop > window.innerHeight / 2) {
          window.scrollBy({ top: window.innerHeight / 2, left: 0, behavior: 'smooth' });
        }
      }, 100);
    },

    //handleScrollImage(e, el, node) { return service.handleScroll(el, (2 - node.data.key) * 100); },
    handleScrollDlist(e, el, node) {
      this.dlistDark = el.getBoundingClientRect().top < this.pageTop.imageHeight ? true: false;
      //console.log('handleScrollDlist', el.getBoundingClientRect().top);
    }
  }
}
</script>

<style scoped lang="less">
@import '../styles/vars.less';

@colorDark: #222222dd;

.framee-bg {
  //background-image: url(~@/assets/tuurro/tuurro.t6.f7.png);
  //background-image: url(~@/assets/framee/framee01.jpg);
  background-repeat: no-repeat;
  background-size: contain;
  //background-origin: border-box;
  background-position: 50% 0;
  background-attachment: fixed;
  height: var(--tc-height);
  .mvtn-transition(filter 0.5s);

  &.fade { filter: invert(1) opacity(0.3); }

  //@media @phone { min-height: calc(100vh - var(--tcwm-height)); }
  @media @phone {
    //min-height: calc(var(--vh, 1vh) * 100 - var(--tcwm-height));
    min-height: var(--vh);
  }
}

//.text-container::v-deep {
.text-container {
  .mvtn-transition-all(1s);

  .text-inner {
    font-size: 1.4em;
    width: 100%;
    //padding-bottom: 30px;

    .dlist-container {
      //background-color: @colorDark;
      width: 100%;
      padding-bottom: 30px;
      .mvtn-transition-color(1s);

      //@media @phone { padding-bottom: 80px; }
    }
  }
}

.images-container { padding: 0 15px; }
.dark { background-color: @colorDark; }

.specs-container {
  .specs-container();

  //.bg-img-container { background-image: url(~@/assets/figuur/figuurc02.png); }
}

</style>