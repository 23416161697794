<template>
  <div class="content">
    <!--PageTop :name="tile.name" :title="tile.title" /-->
    <div class="text-container" :key="tile.name">
      <dl class="dlist" v-for="(item, index) in tile.description" :key="index">
        <dt>{{ item.title }}</dt>
        <dd>{{ item.text }}</dd>
      </dl>
      <!--div class="image-container flex" ref="images">
        <div v-tscroll="handleScroll" class="image-item flex column scrollable bg-cappuccino" v-for="(img, i) in tile.images" :data-name="img.name" :key="i">
          <SvgImage :svg="img" /-->
          <!--img class="img" :src="require(`@/assets/${img.src}`)" />
          <span class="img-title" v-html="img.name"></span>
        </div>
      </div-->
      <FlyingImages :images="tile.images" />

      <div class="title-container flex space" ref="specs">
        <div class="title" @click="toggleShowSpecs()">Specifications</div>
        <div class="btn-container flex centerv gap">
          <div :class="['btn specs', { shaded: !showSpecs }]" @click="toggleMetricImperial()">{{ btnMetricImperial }}</div>
          <div :class="['btn icon', { active: showSpecs }]" @click="toggleShowSpecs()" :title="showSpecsTitle">
            <font-awesome-icon icon="chevron-right" />
          </div>
        </div>
      </div>
      <VTransition>
        <div v-if="showSpecs" class="specs-container flex column">
          <div class="bg-img-container"></div>
          <div class="specs-inner flex wrap gap">
            <SpecTable :specs="specsData()('figuur', 'satellites')" :center="true" />
            <SpecTable :specs="specsData()('figuur', 'subwoofers')" />
            <SpecTable :specs="specsData()('figuur', 'amplifiersd')" />
            <SpecTable :specs="specsData()('figuur', 'amplifiersp')" />
          </div>
          <div class="note-container">
            <SpecNote :name="'figuur'" />
          </div>
        </div>
      </VTransition>
      <div :class="['page-footer backdrop-blur red flex center', { active: showSpecs }]">
        <SvgImage :svg="mvtnLogo" />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import PageTop from '@/components/PageTop.vue'
import SpecTable from '@/components/SpecTable.vue'
import SpecNote from '@/components/SpecNote.vue'
import VTransition from '@/components/VTransition.vue'
import SvgImage from '@/components/SvgImage.vue'
import FlyingImages from '@/components/FlyingImages.vue'
import { mapGetters, mapMutations } from 'vuex'
import { service } from '@/store/service'

export default {
  name: 'Figuur',

  components: {
    PageTop,
    SpecTable,
    SpecNote,
    SvgImage,
    VTransition,
    SvgImage,
    FlyingImages
  },

  data() {
    return {
      tile: this.tileByName()('figuur'),
      showSpecs: false,
      specImages: service.svg.figuur,
      mvtnLogo: service.svg.mvtn.logo
    }
  },

  computed: {
    ...mapGetters(['menu', 'isMetric']),

    btnMetricImperial() { return this.isMetric ? 'Imperial' : 'Metric'; },
    showSpecsTitle() { return this.showSpecs ? 'Hide specifications' : 'Show specifications'; }
  },

  /*created() {
    //console.log('created', this.specsData()('figuur', 'satellites'));
    console.log('figuur created');
    //service.handleScroll(el, 0);
  },*/

  /*mounted() {
    this.$nextTick(() => {
      //console.log('figuur handle scroll', this.$refs.images.children[0].getBoundingClientRect().top);
      this.$refs.images.children.forEach(el => { service.handleScroll(el, 0); } );
    });
  },*/

  methods: {
    ...mapGetters(['tileByName', 'specsData']),
    ...mapMutations(['toggleMetricImperial']),

    toggleShowSpecs() {
      this.showSpecs = !this.showSpecs;

      setTimeout(() => {
        if (this.$refs.specs.offsetTop > window.innerHeight / 2) {
          window.scrollBy({ top: window.innerHeight / 2, left: 0, behavior: 'smooth' });
        }
      }, 100);
    },

    //handleScroll(e, el, node) { return service.handleScroll(el, (2 - node.data.key) * 100); }
  }
}
</script>

<style scoped lang="less">
@import '../styles/vars.less';

.bg-cappuccino {
	//background-color: @cappuccino-color;
	//background-image: linear-gradient(to bottom, @main-c-bg, @cappuccino-color);
	background-image: linear-gradient(to bottom, @colorCappuccino2, @colorCappuccino1);
}

:deep(.images-container) {
  padding-top: 30px;

  .image-item {
  	background-image: linear-gradient(to bottom, @colorCappuccino2, @colorCappuccino1);

    //flex: 1;
    //justify-content: flex-end;
    //width: 1%;
    //min-width: 250px;

    /*.img-title {
      display: flex;
      justify-content: center;
      font-size: 5vw;
      //margin-top: -2.5vw;
      padding-bottom: 2vw;
      color: #444444;
    }*/

    /*&::after {
      content: attr(data-name);
      display: flex;
      justify-content: center;
      font-size: 5vw;
      margin-top: -2.5vw;
      padding-bottom: 2vw;
      color: #444444;
    }*/
  }
}

.specs-container {
  .specs-container();

  .bg-img-container {
    background-image: url(~@/assets/figuur/figuurc02.png);
  }

  /*&::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url(~@/assets/figuur/figuurc02.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-origin: border-box;
    background-attachment: fixed;
    filter: grayscale(1);
    opacity: 0.15;
    z-index: -10;
  }*/
}

</style>
