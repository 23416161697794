<template>
  <div class="spec-note-container flex column grow">
    <div class="type-container flex">
      <div class="type inverse flex centerv">{{ note.name }}</div>
      <!--span class="btn" @click="toggleSpecs">open</span-->
    </div>
    <div class="flex grow wrap">
      <div v-for="(item, i) in note.items" class="note flex column" :key="i">
        <div class="name">
          <span v-html="item.id" class="name-id"></span>
          <span v-html="item.name"></span>
        </div>

        <div v-if="item.table" class="table">
          <div class="head flex">
            <div v-for="(cell, ci) in item.table.head" :key="ci" v-html="cell" :class="['cell title', { first: ci === 0 }, { second: ci === 1 }]">
            </div>
          </div>
          
          <div v-for="(trow, roi) in item.table.rows" :key="roi" class="row flex">
            <div class="cell title first flex center">
              <span v-html="trow.name" class="rotate"></span>
            </div>
            <div>
              <div v-for="(row, ri) in trow.row" :key="ri" class="flex">
                <span v-html="row.name" class="cell second"></span>
                <span v-for="(cell, ci) in row.data" :key="ci" v-html="cell" class="cell"></span>
              </div>
            </div>
          </div>
        </div>

        <div class="flex grow wrap">
          <div v-for="(t, j) in item.texts" :key="j" class="text flex column">
            <span v-if="t.name" class="text-name" v-html="t.name"></span>
            <span class="text-text" v-html="t.text"></span>
          </div>
        </div>

        <div v-if="item.foot" class="foot flex">
          <span v-html="item.foot.name" class="foot-name"></span>
          <span v-html="item.foot.text" class="foot-text flex column"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
//import Menubar from '@/components/Menubar.vue'
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'SpecNote',

  props: {
    name: String
  },

  computed: {
    note() { return this.specsNote()(this.name); }
  },

  /*created() {
    console.log('SpecNote', this.note, this.specsNote()(this.name));
  },*/

  methods: {
    ...mapGetters(['specsNote']),
  }
}
</script>

<style scoped lang="less">
@import '../styles/vars.less';


//.spec-note-container::v-deep {
.spec-note-container {
  //font-size: 0.9em;
  //padding: 0 15px 15px;
  padding-bottom: 15px;
  color: @colorTableBright;

  .type-container { .type-container(); }

  :deep(.note) {
    position: relative;
    margin: 15px 15px 0 0;
    border: 1px solid @colorSemiGrey;
    border-radius: 11px;
    opacity: 0.6;
    cursor: default;
    .mvtn-transition(opacity 0.25s);

    &:hover { opacity: 1; }

    .name {
      padding: 7px 10px;
      //width: 20px;
      //height: 20px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      background-color: @colorSemiWhite;
      color: #000;
      font-weight: 400;

      .name-id {
        font-weight: 800;
        padding-right: 15px;
      }
    }

    .text {
      padding: 15px;

      .text-name {
        border-bottom: 1px solid @colorTableBright;
        padding-bottom: 3px;
      }

      .text-text {
        font-size: 0.9em;
        padding-top: 5px;
      }

      ul {
        margin: 0;
        padding-inline-start: 20px;
        font-size: 0.9em;
        white-space: nowrap;

        li { padding: 2px 0; }
      }
    }

    .table {
      white-space: nowrap;
      color: @colorTable;
      padding-bottom: 7px;

      .head {
        padding: 10px 0 3px 0;

        .cell { border: none; }
      }

      .row {
        &:nth-child(2n) { background-color: @colorSemiBlack; }
      }

      .cell {
        width: 10rem;
        border-top: 1px solid @colorTableBorder;
        padding: 8px 5px;
        font-size: 0.8em;

        //&:nth-last-of-type(1) { padding-right: 15px; }

        &.title {
          font-size: 0.9em;
          color: @colorTableBright;
        }

        &.first {
          width: 4rem;
          padding-left: 15px;
          padding-right: 10px;
        }

        &.second { width: 8.5rem; }

        .rotate { transform: rotate(-90deg); }
      }
    }

    .foot {
      //border-top: 1px solid @colorSemiGrey;
      background-color: @colorSemiBlack;
      color: @colorTable;
      padding: 10px 15px;
      font-size: 0.9em;

      .foot-name { margin-right: 10px; }
    }
  }
}

</style>
