<template>
  <div id="app">
    <!--div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div-->
    <Menubar v-if="$route.name !== 'Home'" :fixed="true"></Menubar>
    <router-view v-slot="{ Component }">
      <transition name="page">
        <component :is="Component" />
      </transition>
    </router-view>
  </div>
</template>

<script>
// @ is an alias to /src
import Menubar from '@/components/Menubar.vue'

export default {
  name: 'App',
  components: {
    Menubar
  }
}
</script>

<style lang="less">

@import 'styles/main.less';
@import 'styles/vars.less';

#app {
  font-family: Roboto, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.page-enter-active, .page-leave-active {
  .mvtn-transition(opacity 0.35s);
}
.page-enter-from, .page-leave-to /* .page-leave-active below version 2.1.8 */ {
  opacity: 0;
}
//.page-leave, .page-enter-to /* .page-leave-active below version 2.1.8 */ {
//  opacity: 0.3;
//}
</style>
