<template>
  <svg version="1.1" :id="svg.id" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
    :x="svg.x" :y="svg.y" :viewBox="svg.viewBox" :style="svg.style" xml:space="preserve">
    <g v-if="svg.g" v-html="svg.g"></g>
  </svg>
</template>

<script>
//import { mapGetters, mapMutations } from 'vuex'
import { service } from '@/store/service'

export default {
  name: 'SvgImage',

  props: {
    svg: {}
  },

  computed: {
  },

  created() {
    console.log(this.svg);
  },

  methods: {
    //...mapGetters(['specsNote']),
  }
}
</script>

<style scoped lang="less">
@import '../styles/vars.less';
</style>
