// TUURRO
const tile = {
  name: 'TUURRO',
  url: '/tuurro',
  title: '<i class="mark s2">Simple</i> <i class="mark s1">outside</i> <i class="mark s3">complex</i> <i class="mark s2">inside</i>',
  wmsize: 26.5,
  img: 'tuurro/tuurro.t6.f7.png', imgalt: 'tuurro/tuurro.t6.p.png',
  pageTop: {
    full: true,
    image: 'tuurro/tuurro.t6.f8.png',
    vertical: true
    //colorWM: '#00000033',
    //colorHeader: '#00000044'
  },
  images: [
    [ { src: 'tuurro/tuurro.t6.rb.png', name: 'Tuurro.t6' },
      { src: 'tuurro/tuurro.t6.rw.png', name: 'Tuurro.t6' },
      { src: 'tuurro/tuurro.t6.inside.png', name: 'Tuurro.t6' } ],
    [ { src: 'tuurro/tuurro.c6.b.png', name: 'Tuurro.c6' },
      { src: 'tuurro/tuurro.c6.w.png', name: 'Tuurro.c6' } ],
    [ { src: 'tuurro/tuurro.s10.b.png', name: 'Tuurro.s10' },
      { src: 'tuurro/tuurro.s10.w.png', name: 'Tuurro.s10' } ]
  ],
  /*scenes: [
    { src: 'tuurro/scene.11.jpg', ratio: 1.6 }
  ],*/
  description: [
    {
      title: "Technology",
      text: "Tuurro available both active and passive versions. Active version has an ICEpower amplifier and a MiniDSP processor on board."
    },
    {
      title: "Cabinet",
      text: "The cabinet looks simple outside but has a complex construction inside to reduce internal resonances.",
    },
    {
      title: "Drivers",
      text: `6" midwoofers with reinforced aluminum or ceramic cones for optimum piston operation.
        <p>True ribbon tweeter made of extreemly thin aluminum foil.</p>
        <p>10" or 12" aluminum cone subwoofer drivers with 56mm peak excursion.</p>`
    }
  ],
  over: false,
  specs: {
    satellites: {
      type: 'Satellites',
      head: {
        cabinet: 'Cabinet',
        amplifier: {
          name: 'Amplifier',
          power: 'Power (RMS)',
          damping: 'Damping factor',
          dynamicRange: 'Dynamic range',
          thd: 'THD+N @ 1W / 260W',
          protection: 'Protection'
        },
        inputs: {
          name: 'Inputs &#xb9;',  // superscript 1
          unbalanced: 'Unbalanced',
          balanced: 'Balanced'
        },
        outputs: {
          name: 'Outputs &#xb9;', // superscript 1
          unbalanced: 'Unbalanced',
          balanced: 'Balanced'
        },
        dsp: {
          name: 'DSP &#xb2;', // superscript 2
          filter: 'Filters',
          eq: 'Parametric EQs',
          delay: 'Delay',
          features: 'Matrix mixer / Invert polarity'
        },
        midwoofer: {
          name: 'Midwoofer',
          cone: 'Cone',
          xmax: 'Linear excursion'
        },
        tweeter: {
          name: 'Tweeter',
          ribbon: 'Ribbon material',
          size: 'Ribbon size',
          mass: 'Ribbon mass'
        },
        powerp: 'Power handling (RMS)',
        impedance: 'Impedance',
        sensetivity: 'Sensitivity (1W/1m)',
        fresponce: 'Frequency response (±3dB)',  // &#x2074; superscript 4
        //protection: 'Protection',
        dimension: 'Dimensions without feet (W x H x D)',
        weight: 'Weight'
      },
      items: [
        {
          name: 'Tuurro t6',
          //image: 'figuur/figuur.a.svg',
          cabinet: 'Vented',
          amplifier: {
            name: 'ICEpower',
            power: '2 x 300W',
            damping: '660',
            dynamicRange: '113dBA',
            thd: '0.005% / 0.1%',
            protection: '<i class="r-icon" title="Over temperature">OT</i><i class="r-icon" title="Over current">OC</i><i class="r-icon" title="Short circuit">SC</i>'
          },
          inputs: {
            name: 'Configurable via DSP',
            unbalanced: 'RCA',
            balanced: 'XLR/TRS combo',//'&#x26AC;'
          },
          outputs: {
            name: 'Configurable via DSP',
            unbalanced: 'RCA',//'&#x26AC;',
            balanced: 'XLR'//'&#x26AC;'
          },
          dsp: {
            name: '56bit / 48kHz', // superscript 2
            filter: 'LPF / HPF',
            eq: 'Peak / Shelf',
            delay: 'Up to 7.5ms (258cm)',
            features: '+ / +'
          },
          midwoofer: {
            name: '2 x 6"',
            cone: 'Aluminum or Ceramic',
            xmax: '11mm'
          },
          tweeter: {
            metric: {
              name: 'True ribbon',
              ribbon: 'Aluminum',
              size: '70 x 19 x 0.012mm',
              mass: '0.039g'
            },
            imperial: {
              name: 'True ribbon',
              ribbon: 'Aluminum',
              size: '2.76" x 0.75" x 0.00047"',
              mass: '0.00138oz',
            }
          },
          powerp: '&#x2012;',
          impedance: '&#x2012;',
          sensetivity: '&#x2012;', //'91.5dB',
          //fresponce: '35 &#x2027;&#x2027; 40kHz',
          fresponce: {
            name: '35 &#x2027;&#x2027; 40kHz',  // &#x2074; superscript 4
            title: 'With DSP'
          },
            //protection: '',   //'Self-resetting',
          dimension: {
            metric: '260 x 1230 x 260mm',
            imperial: '10.25" x 48.45" x 10.25"'
          },
          weight: {
            metric: '? kg',
            imperial: '? lbs'
          }
        },
        {
          name: 'Tuurro t6p',
          //image: 'figuur/figuur.a.svg',
          cabinet: 'Vented',
          amplifier: '&#x2012;',
          inputs: '&#x2012;',
          outputs: '&#x2012;',
          dsp: '&#x2012;',
          midwoofer: {
            name: '2 x 6"',
            cone: 'Aluminum or Ceramic',
            xmax: '11mm'
          },
          tweeter: {
            metric: {
              name: 'True ribbon',
              ribbon: 'Aluminum',
              size: '70 x 19 x 0.012mm',
              mass: '0.039g'
            },
            imperial: {
              name: 'True ribbon',
              ribbon: 'Aluminum',
              size: '2.76" x 0.75" x 0.00047"',
              mass: '0.00138oz',
            }
          },
          powerp: '150W',
          impedance: '4&Omega;',
          sensetivity: '91dB',
          fresponce: '43 &#x2027;&#x2027; 40kHz',
          //protection: '',   //'Self-resetting',
          dimension: {
            metric: '260 x 1230 x 260mm',
            imperial: '10.25" x 48.45" x 10.25"'
          },
          weight: {
            metric: '? kg',
            imperial: '? lbs'
          }
        },
        {
          name: 'Tuurro c6',
          //image: 'figuur/figuur.a.svg',
          cabinet: 'Vented',
          amplifier: {
            name: 'ICEpower',
            power: '2 x 300W',
            damping: '660',
            dynamicRange: '113dBA',
            thd: '0.005% / 0.1%',
            protection: '<i class="r-icon" title="Over temperature">OT</i><i class="r-icon" title="Over current">OC</i><i class="r-icon" title="Short circuit">SC</i>'
          },
          inputs: {
            name: 'Configurable via DSP',
            unbalanced: 'RCA',
            balanced: 'XLR/TRS combo',//'&#x26AC;'
          },
          outputs: {
            name: 'Configurable via DSP',
            unbalanced: 'RCA',//'&#x26AC;',
            balanced: 'XLR'//'&#x26AC;'
          },
          dsp: {
            name: '56bit / 48kHz', // superscript 2
            filter: 'LPF / HPF',
            eq: 'Peak / Shelf',
            delay: 'Up to 7.5ms (258cm)',
            features: '+ / +'
          },
          midwoofer: {
            name: '2 x 6"',
            cone: 'Aluminum or Ceramic',
            xmax: '11mm'
          },
          tweeter: {
            metric: {
              name: 'True ribbon',
              ribbon: 'Aluminum',
              size: '70 x 19 x 0.012mm',
              mass: '0.039g'
            },
            imperial: {
              name: 'True ribbon',
              ribbon: 'Aluminum',
              size: '2.76" x 0.75" x 0.00047"',
              mass: '0.00138oz',
            }
          },
          powerp: '&#x2012;',
          impedance: '&#x2012;',
          sensetivity: '&#x2012;', //'91.5dB',
          fresponce: {
            name: '35 &#x2027;&#x2027; 40kHz',  // &#x2074; superscript 4
            title: 'With DSP'
          },
          //protection: '',   //'Self-resetting',
          dimension: {
            metric: '760 x 260 x 400mm',
            imperial: '29.95" x 10.25" x 15.75"'
          },
          weight: {
            metric: '? kg',
            imperial: '? lbs'
          }
        },
        {
          name: 'Tuurro c6p',
          //image: 'figuur/figuur.a.svg',
          cabinet: 'Vented',
          amplifier: '&#x2012;',
          inputs: '&#x2012;',
          outputs: '&#x2012;',
          dsp: '&#x2012;',
          midwoofer: {
            name: '2 x 6"',
            cone: 'Aluminum or Ceramic',
            xmax: '11mm'
          },
          tweeter: {
            metric: {
              name: 'True ribbon',
              ribbon: 'Aluminum',
              size: '70 x 19 x 0.012mm',
              mass: '0.039g'
            },
            imperial: {
              name: 'True ribbon',
              ribbon: 'Aluminum',
              size: '2.76" x 0.75" x 0.00047"',
              mass: '0.00138oz',
            }
          },
          powerp: '150W',
          impedance: '4&Omega;',
          sensetivity: '91dB',
          fresponce: '43 &#x2027;&#x2027; 40kHz',
          //protection: '',   //'Self-resetting',
          dimension: {
            metric: '760 x 260 x 400mm',
            imperial: '29.95" x 10.25" x 15.75"'
          },
          weight: {
            metric: '? kg',
            imperial: '? lbs'
          }
        },
      ]
    },
    subwoofers: {
      type: 'Subwoofers',
      head: {
        cabinet: 'Cabinet',
        amplifier: {
          name: 'Amplifier',
          power: 'Power (RMS)',
          damping: 'Damping factor',
          dynamicRange: 'Dynamic range',
          thd: 'THD+N @ 1W / 260W',
          protection: 'Protection'
        },
        inputs: {
          name: 'Inputs &#xb9;',  // superscript 1
          unbalanced: 'Unbalanced',
          balanced: 'Balanced'
        },
        outputs: {
          name: 'Outputs &#xb9;', // superscript 1
          unbalanced: 'Unbalanced',
          balanced: 'Balanced'
        },
        dsp: {
          name: 'DSP &#xb2;', // superscript 2
          filter: 'Filters',
          eq: 'Parametric EQs',
          delay: 'Delay',
          features: 'Matrix mixer / Invert polarity'
        },
        driver: {
          name: 'Driver',
          cone: 'Cone',
          xmax: 'Linear excursion',
          xmaxp: 'Peak excursion'
        },
        fresponce: {
          name: 'Frequency response (±3dB)',  // &#x2074; superscript 4
          title: 'With DSP'
        },
        //protection: 'Protection',
        dimension: {
          name: 'Dimensions (W x H x D)',
          title: "Without feet"
        },
        weight: 'Weight'
      },
      items: [
        {
          name: 'Tuurro s10',
          //image: 'figuur/figuur.a.svg',
          cabinet: 'Closed',
          amplifier: {
            name: 'ICEpower',
            power: '300W',
            damping: '660',
            dynamicRange: '113dBA',
            thd: '0.005% / 0.1%',
            protection: '<i class="r-icon" title="Over temperature">OT</i><i class="r-icon" title="Over current">OC</i><i class="r-icon" title="Short circuit">SC</i>'
          },
          inputs: {
            name: 'Configurable via DSP',
            unbalanced: '2 x RCA',
            balanced: '2 x XLR/TRS combo'
          },
          outputs: {
            name: 'Configurable via DSP',
            unbalanced: '2 x RCA',
            balanced: '2 x XLR'
          },
          dsp: {
            name: '56bit / 48kHz', // superscript 2
            filter: 'LPF / HPF',
            eq: 'Peak / Shelf',
            delay: 'Up to 7.5ms (258cm)',
            features: '+ / +'
          },
          driver: {
            name: '1 x 10"',
            cone: 'Aluminum',
            xmax: '25mm',
            xmaxp: '56mm'
          },
          fresponce: '23 &#x2027;&#x2027; 300Hz',
          //protection: '',   //'Self-resetting',
          dimension: {
            metric: '354 x 354 x 354mm',
            imperial: '13.95" x 13.95" x 13.95"'
          },
          weight: {
            metric: '? kg',
            imperial: '? lbs'
          }
        },
        {
          name: 'Tuurro s12',
          //image: 'figuur/figuur.a.svg',
          cabinet: 'Closed',
          amplifier: {
            name: 'ICEpower',
            power: '300W',
            damping: '660',
            dynamicRange: '113dBA',
            thd: '0.005%/0.1%',
            protection: '<i class="r-icon" title="Over temperature">OT</i><i class="r-icon" title="Over current">OC</i><i class="r-icon" title="Short circuit">SC</i>'
          },
          inputs: {
            name: 'Configurable via DSP',
            unbalanced: '2 x RCA',
            balanced: '2 x XLR/TRS combo',//'&#x26AC;'
          },
          outputs: {
            name: 'Configurable via DSP',
            unbalanced: '2 x RCA',//'&#x26AC;',
            balanced: '2 x XLR'//'&#x26AC;'
          },
          dsp: {
            name: '56bit / 48kHz', // superscript 2
            filter: 'LPF / HPF',
            eq: 'Peak / Shelf',
            delay: 'Up to 7.5ms (258cm)',
            features: '+ / +'
          },
          driver: {
            name: '1 x 12"',
            cone: 'Aluminum',
            xmax: '25mm',
            xmaxp: '56mm'
          },
          fresponce: '20 &#x2027;&#x2027; 300Hz',
          //protection: '',   //'Self-resetting',
          dimension: {
            metric: '400 x 400 x 400mm',
            imperial: '15.75" x 15.75" x 15.75"'
          },
          weight: {
            metric: '? kg',
            imperial: '? lbs'
          }
        }
      ]
    },

    note: {
      name: 'Notes',
      items: [
        {
          id: 1,
          name: 'Inputs/Outputs options', //'&#xb9;',
          table: {
            head: [ '&nbsp;', '&nbsp;', 't6 / c6', 't6.u / c6.u', 't6.e / c6.e', 's10 / s12', 's10.u / s12.u', 's10.e / s12.e' ],
            rows: [
              {
                name: 'Inputs',
                row: {
                  unbalanced: {
                    name: 'Unbalanced',
                    data: ['1 x RCA', '1 x RCA', '1 x RCA', '2 x RCA', '2 x RCA', '1 x RCA']
                  },
                  balanced: {
                    name: 'Balanced',
                    data: ['1 x XLR/TRS', '&#x2012;', '&#x2012;', '2 x XLR/TRS', '&#x2012;', '&#x2012;']
                  }
                }
              },
              {
                name: 'Outputs',
                row: {
                  unbalanced: {
                    name: 'Unbalanced',
                    data: ['1 x RCA', '1 x RCA', '&#x2012;', '2 x RCA', '2 x RCA', '&#x2012;']
                  },
                  balanced: {
                    name: 'Balanced',
                    data: ['1 x XLR', '&#x2012;', '&#x2012;', '2 x XLR', '&#x2012;', '&#x2012;']
                  }
                }
              }
            ]
          },
          foot: {
            name: 'Note:',
            text: `<span>Balanced and Unbalanced do not work simultaneously.</span>`
          }
        },
        /*{
          id: 1,
          name: 'Inputs/Outputs options', //'&#xb9;',
          texts: [
            {
              name: 's10 / s12',
              text: `Inputs: <ul>
                      <li>Unbalanced: 2 x RCA</li>
                      <li>Balanced: 2 x XLR/TRS</li>
                    </ul>
                    Outputs: <ul>
                      <li>Unbalanced: 2 x RCA</li>
                      <li>Balanced: 2 x XLR</li>
                    </ul>`
            },
            {
              name: 's10.u / s12.u',
              text: `Inputs: <ul><li>Unbalanced: 2 x RCA</li></ul>
                    Outputs: <ul><li>Unbalanced: 2 x RCA</li></ul>`
            },
            {
              name: 's10.e / s12.e',
              text: `Inputs: <ul><li>Unbalanced: 1 x RCA</li></ul>`,
            }
          ],
          foot: {
            name: 'Note:',
            text: `<span>Balanced and Unbalanced do not work simultaneously.</span>
                  <span>2 inputs and 2 outputs total.</span>`
          }
        },*/
        {
          id: 2,
          name: 'DSP', //'&#xb2;',
          texts: [
            {
              name: 'Filters',
              text: `<ul>
                      <li>Butterworth up to 8th order (6 &#x2027;&#x2027; 48dB/oct)</li>
                      <li>Linkwitz-Riley up to 8th order (12 &#x2027;&#x2027; 48dB/oct)</li>
                      <li>Bessel 2nd order</li>
                      <li>Advanced mode for custom biquad input</li>
                    </ul>`
            },
            {
              name: 'Parametric EQs',
              text: `<ul>
                      <li>5 bands per input</li>
                      <li>5 bands per output</li>
                      <li>Peak / Shelf switchable</li>
                    </ul>`
            }
          ]
        },
        {
          id: 3,
          name: 'Finish options', //'&#xb9;',
          texts: [
            {
              text: `<ul>
                      <li>Matt lacquer (any color)</li>
                      <li>High-gloss lacquer (any color)</li>
                      <li>Veneer (nearly any)</li>
                      <li>Fabric</li>
                      <li>Synthetic leather</li>
                      <li>Custom finish upon request</li>
                    </ul>`
            }
          ],
        }
      ]
    }
  }
}

export const tuurro = { tile }
